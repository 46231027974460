@rate: 1.406;

.sub-block {
  margin-top: 31px;
  height: 281px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #C55A48;
  background: #C45B48;

}

input:focus {
  border: 1px solid rgba(15, 37, 35, 0.2) !important;

}

.sub-row {
  display: flex;
  align-items: center;
  justify-content: center;
}



.sub-input {
  width: 300px;
  height: 65px;
  flex-shrink: 0;
  color: #C55A48;
  text-align: left;
  font-family: Inter-Regular;
  font-size: calc(1.875rem / @rate);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  background: #FFFFFF;
  border-radius: 4px 0px 0px 4px;
  border: 0;
  padding: 0 25px;



}
.sub-btn:hover{
  background:  #676599 !important;;
  text-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

}

.sub-btn {
  width: 144px;
  height: 65px;
  line-height: 65px;
  flex-shrink: 0;
  background:  #DD9689;;
  color: #FFF;
  text-align: center;
  font-size: 23px;
  // border: 1px solid #DD9689;
  cursor: pointer;
  font-family: Inter-Regular;
  font-weight: 500;
  font-size: 15px;
  color: #FFFFFF;
  text-align: center;
  font-style: normal;
  text-transform: none;

  

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  font-family: Inter-Regular !important;
  font-weight: 300 !important;
  font-size: 15px !important;
  color: #A1A1A1 !important;
  text-align: left !important;
  font-style: normal;
  text-transform: none;
}

::-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-family: Inter-Regular;
  font-weight: 300;
  font-size: 15px;
  color: #A1A1A1;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-family: Inter-Regular;
  font-weight: 300;
  font-size: 15px;
  color: #A1A1A1;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: Inter-Regular;
  font-weight: 300;
  font-size: 15px;
  color: #A1A1A1;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
}

.success-line{
  // border: 1px solid #00DB7F;
}

.failed-line{
  border: 1px solid #C45B48 !important;
}

.sub-tip {
  width: 377px;
  font-family: IBM Plex Sans, IBM Plex Sans;;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  font-style: normal;
  text-transform: none;
  margin-bottom: 40px;
}

.failed{
  height: 18px;
  font-family: Inter, Inter;
  font-weight: 300;
  font-size: 15px;
  color: #fff;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}


.menu {
  display: flex;
  height: 100%;
}
// .menu-item-active:hover {
//   background-color: #fff !important;
//   color: #c55a48 !important;
//   border-bottom: 3px solid #c55a48 !important;
//   border-radius: 0;
//   // span{
//   //   border-bottom: 3px solid #C55A48 !important;
//   //   display: inline-block;
//   //  }
// }
.menu-item-active {
  // background-color: #c55a48;
  // color: #fff;
  span {
    border-bottom: 0;
    display: inline-block;
  }
}

.menuactive{
  font-family: Inter-Bold !important;
  font-weight: bold !important;
  position: relative;
}

.menuactive>.line1{
  width: 36px;
  height: 5px;
  background: #C45B48;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -18px;
  border-radius: 2px;
}

.menu-btn {
  cursor: pointer;
  border-radius: 0.3125rem;
  width: calc(11.25rem / @rate);
  height: 72px;
  text-align: center;

    font-family: Inter-Medium;
    font-weight: 500;
    font-size: 15px;
    color: #292929;
    line-height: 72px;
    font-style: normal;
    text-transform: none;
  

  margin-left: calc(1.25rem / @rate);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menu-btn:hover {
  color: rgba(196, 90, 72, 1);

}

@media screen and (max-width: 767px) {

  .sub-block {
    margin-top: 24px;

    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      font-size: 16px;
    }
  }

  .sub-input {
    width: 200px;
  }

  .sub-btn {
    width: 100px;
    font-size: 16px;

  }

  .sub-tip {
    font-size: 12px;
  }
}
@primary-color: #A01E28;