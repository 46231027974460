.agix-top-menu{
    position: absolute;
}
.agix-content {
    position: relative;
    z-index: 999;
    padding: 0 200px;
    margin-top: -294px;

}
.agix-content1{
    padding-top: 60px;
}
.agix-temp0 {
    width: 100%;
    height: 294px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.agix-temp01 {
    color: #fff;
    font-family: Inter;
    font-size: 80px;
    // font-weight: 900;
    line-height: 96.82px;
    text-align: left;
}

.agix-temp02 {
    color: #fff;
    font-family: Inter;
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
    margin-top: 20px;
}


.agix-temp1 {
    font-family: Inter;
    font-size: 30px;
    font-weight: 700;
    line-height: 42px;
    text-align: left;
    color: rgba(196, 91, 72, 1);
}

.agix-temp2 {
    font-family: Inter;
    font-size: 22px;
    font-weight: 400;
    line-height: 34px;
    text-align: justified;
    color: rgba(67, 67, 67, 1);
    margin-top: 40px;
}

.agix-temp21 {
    color: rgba(130, 106, 200, 1);
    font-weight: bold;

}

.agix-temp3 {
    font-family: Inter;
    font-size: 22px;
    font-weight: 400;
    line-height: 34px;
    text-align: justified;
    color: rgba(67, 67, 67, 1);
    margin-top: 40px;

}

.agix-temp4 {
    width: 186px;
    height: 65px;
    border-radius: 8px;
    background: rgba(130, 106, 200, 1);
    font-family: Inter;
    font-size: 24px;
    font-weight: 800;
    color: #fff;
    line-height: 65px;
    margin-top: 40px;
    margin-bottom: 105px;
    text-align: center;
    cursor: pointer;
}

.agix-temp5 {
    // height: 154px;
    padding: 40px 200px 40px 200px;
    width: 100vw;
    background: #efefef;
    margin-left: -200px;
    p{
        margin-bottom: 10px;
        color: #000;
        font-size: 17px;
    }
    // padding-left: 200px;
}

.agix-temp51 {
    width: 106px;
    height: 106px;
    margin-bottom: -27px;
    margin-left: -6px;
}

.agix-temp52 {
    font-family: Abhaya Libre ExtraBold;
    font-size: 20px;
    font-weight: 800;
    line-height: 23.59px;
    text-align: left;
    color: rgba(51, 51, 51, 1);
}

.agix-temp6 {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 527px;
}

@media screen and (max-width: 767px) {
    .agix-temp0 {
        // height: 224px;
        margin-top: 100px;
        position: relative;
    }

    .agix-temp01 {
        font-size: 40px;
        line-height: 40px;

    }

    .agix-temp02 {
        font-size: 22px;
        line-height: 32px;
    }

    .agix-temp1 {
        font-size: 24px;
        line-height: 32px;

    }

    .agix-temp2 {
        margin-top: 20px;
        font-size: 16px;
        line-height: 24px;

    }

    .agix-temp3 {
        margin-top: 20px;
        font-size: 16px;
        line-height: 24px;

    }

    .agix-content {
        padding: 0 30px;
        // margin-top: -224px;
    }
    .agix-content1{
        padding-top: 20px;
        margin-top: -100px;
    }
    .agix-temp4 {
        margin-bottom: 50px;
        margin-top: 20px;
        width: calc(186px / 2);
        height: calc(65px / 2);
        line-height: calc(65px / 2);
        font-size: 14px;
    }

    .agix-top-menu{
        top: 77px;
        right:-20px
    }

    .agix-temp5 {
        padding: 20px 10px;
        width: 100vw;
        background: #efefef;
        margin-left: -30px;
        padding-left: 30px;
        p{
            margin-bottom: 10px;
            color: #000;
            font-size: 14px;
        }
    }



    .agix-temp01 {
        font-size: 28px;
        line-height: 32px;
    }
    
    .agix-temp02 {
        font-size: 16px;
        line-height: 24px;
        margin-top: 0;
    }
}
@primary-color: #A01E28;