.companies {
   &-content {
      margin-bottom: 50px;


   }

   &-title {
      color: var(--Style, #826AC8);
      font-family: Inter;
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 28px;
      /* 93.333% */
      text-transform: capitalize;
      margin-bottom: 20px;
   }

   &-tip {
      display: flex;
      justify-content: space-between;

      &_text1 {
         color: #000;
         font-family: Inter;
         font-size: 16px;
         font-style: normal;
         font-weight: 400;
         line-height: normal;
         margin-bottom: 4px;
      }

      &_text2 {
         color: #54585A;
         font-family: Inter;
         font-size: 14px;
         font-style: normal;
         font-weight: 400;
         line-height: 24px;
         margin-bottom: 4px;
         /* 171.429% */
      }

      &_opt {
         color: var(--03, #5E3290);
         font-family: Inter;
         font-size: 20px;
         font-style: normal;
         font-weight: 600;
         line-height: normal;
         display: flex;
         justify-content: center;
         align-items: center;
         cursor: pointer;
      }
   }

   &-table {
      position: relative;
      margin-bottom: 34px;

   }

   &-deep {
      margin-bottom: 80px;

      &_title {
         color: var(--Style, #826AC8);
         font-family: Inter;
         font-size: 30px;
         font-style: normal;
         font-weight: 800;
         line-height: 28px;
         /* 93.333% */
         text-transform: capitalize;
         margin-bottom: 30px;
      }

      &_block {
         display: flex;
         border: 1px solid #E5E5E5;
         padding: 13px 13px 17px 0;
         margin-bottom: 12px;
         cursor: pointer;
         &_img {
            width: 528px;
            height: 152px;
            margin-right: 14px;
            .companies-deep_block_imgtag{
               width: 100%;
               height: 100%;
               object-fit: cover;
             }
         }



         &_body{
            display: flex;
            flex-direction: column
         }
         &_tag{
            padding: 6px 10px;
            border-radius: 10px;
            text-align: center;
            background: rgba(196, 90, 72, 0.10);
            color: var(--c-45-a-48, #C45A48);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 175% */
            display: inline;
         }
         &_title{
            color: #000;
            font-family: Inter;
            font-size: 28px;
            font-style: normal;
            margin-top: 25px;
            font-weight: 500;
            line-height: 32px; /* 114.286% */
         }
      }
      &_btn{
         display: inline-flex;
         padding: 10px 20px;
         justify-content: center;
         align-items: center;
         border-radius: 10px;
         background: #F6F6F6;
         color: var(--d-9-d-9-d-9, #333);
         font-family: Inter;
         font-size: 16px;
         font-style: normal;
         font-weight: 400;
         line-height: 28px; /* 175% */
         text-transform: uppercase;
         margin-right: 21px;
         float: right;
         cursor: pointer;
      }

   }

}
@primary-color: #A01E28;