body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.research {
  min-height: 100vh;
  background-color: #fff;
  padding: 51px 0;
  max-width: 1290px;
  margin: auto;
}
.research-list-item-img {
  width: calc(35.3125rem / 1.406);
  height: calc(16.9375rem / 1.406);
  flex-shrink: 0;
  overflow: hidden;
  border-radius: calc(1.25rem / 1.406);
}
.research-list-item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.research-list-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: calc(1.25rem / 1.406);
  background: rgba(96, 96, 96, 0.1);
  width: 100%;
  padding: calc(1.31rem / 1.406) calc(2.25rem / 1.406) calc(1.31rem / 1.406) calc(4.19rem / 1.406);
  margin-bottom: calc(1.94rem / 1.406);
}
.research-list-item-icon {
  width: calc(3.9375rem / 1.406);
  height: calc(3.9375rem / 1.406);
  background-image: url('../../../public/home/file.png');
  background-size: cover;
  background-position: center;
  margin-right: calc(1.25rem / 1.406);
}
.research-list-item:hover {
  background: rgba(196, 90, 72, 0.3);
}
.research-list-item:hover .research-list-item-title {
  color: #c55a48;
  font-weight: 700;
}
.research-list-item:hover .research-list-item-time {
  color: #c55a48;
}
.research-list-item:active {
  background: rgba(196, 90, 72, 0.3);
}
.research-list-item:active .research-list-item-title {
  color: #c55a48;
  font-weight: 700;
}
.research-list-item:active .research-list-item-time {
  color: #c55a48;
}
.research-list-item-title {
  color: #606060;
  font-family: Optima;
  font-size: calc(2.25rem / 1.406);
  font-weight: 700;
  line-height: calc(3.4375rem / 1.406);
  /* 333.333% */
  border-bottom: 4px solid rgba(255, 254, 254, 0);
}
.research-list-item-time {
  color: #606060;
  font-family: Optima;
  font-size: calc(2rem / 1.406);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  line-height: calc(4.25rem / 1.406);
  /* 333.333% */
}
.research-list-item-text {
  display: flex;
}
.research-list-title {
  font-family: Inter-Regular;
  font-weight: 500;
  font-size: 48px;
  color: #C45B48;
  line-height: 62px;
  text-align: center;
  font-style: normal;
  text-transform: none;
  text-align: left;
  margin-bottom: 7px;
}
.research-list-title_tip {
  font-family: Inter-Regular;
  font-weight: 400;
  font-size: 18px;
  color: #292929;
  line-height: 29px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-bottom: 25px;
}
.research-list-item-right-time {
  font-family: Inter-Regular;
  font-weight: 400;
  font-size: 13px;
  color: #A1A1A1;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.research-list-item-left-img {
  width: 697px;
  height: 542px;
  object-fit: cover;
}
.research-list-item-left {
  width: 697px;
  flex-shrink: 0;
  margin-right: 35px;
}
.research-list-block {
  display: flex;
  justify-content: center;
}
.research-list-item-left-info {
  border: 1px solid #EBEBEB;
  width: 697px;
  padding: 36px;
}
.research-list-item-left-tag {
  display: inline-block;
  padding: 0 8px;
  font-family: Inter-Regular;
  font-weight: 500;
  font-size: 11px;
  color: #676599;
  line-height: 17px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  background: rgba(103, 101, 153, 0.1);
  margin-right: 10px;
}
.research-list-item-left-time {
  font-family: Inter-Regular;
  font-weight: 400;
  font-size: 14px;
  color: #A1A1A1;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.research-list-item-left-row {
  margin-bottom: 22px;
}
.research-list-item-left-title {
  font-family: Inter-Medium;
  font-weight: 500;
  font-size: 39px;
  color: #292929;
  line-height: 52px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.research-list-item-right-tip {
  font-family: Inter-Medium;
  font-weight: 500;
  font-size: 21px;
  color: #292929;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.research-list-item-right-item {
  min-height: 168px;
  padding: 25px 0 26px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #EBEBEB;
}
.research-list-item-right-title {
  font-family: Inter-Medium;
  font-weight: 500;
  font-size: 18px;
  color: #292929;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.research-list-item-right-img {
  width: 151px;
  height: 117px;
  object-fit: cover;
}
@media screen and (max-width: 767px) {
  .research {
    padding: 24px;
  }
  .research-list-title {
    font-size: 24px;
    margin-bottom: 24px;
  }
  .research-list-item {
    flex-direction: column;
    padding: 12px;
  }
  .research-list-item-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 5px;
  }
  .research-list-item-title {
    font-size: 18px;
    line-height: 20px;
  }
  .research-list-item-time {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  .research-list-item-img {
    width: calc(100vw - 48px - 24px);
    height: calc((100vw - 48px - 24px) / 2.08);
  }
}
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
  z-index: 999;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.teams3-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.teams3-wrapper .teams3 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.teams3-wrapper .teams3 > .title-wrapper {
  margin: 0 auto 48px;
}
.teams3-wrapper .teams3 .block-top-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 20px 0;
}
.teams3-wrapper .teams3 .block-top-wrapper .block-top {
  display: inline-block;
  text-align: center;
  margin-bottom: 70px;
}
.teams3-wrapper .teams3 .block-top-wrapper .block-top.queue-anim-leaving {
  position: relative !important;
}
.teams3-wrapper .teams3-top-image,
.teams3-wrapper .teams3-top-title,
.teams3-wrapper .teams3-top-job,
.teams3-wrapper .teams3-top-content {
  margin: auto;
  line-height: 1.5;
  text-align: left;
}
.teams3-wrapper .teams3-top-image {
  color: #404040;
  object-fit: cover;
}
.teams3-wrapper .teams3-top-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.teams3-wrapper .teams3-top-title {
  font-size: 24px;
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 120%;
  color: #000000;
  text-transform: capitalize;
  text-align: left;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.teams3-wrapper .teams3-top-title a {
  color: #000 !important;
}
.teams3-wrapper .teams3-top-job {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 160%;
  text-transform: capitalize;
  color: #000000 !important;
  text-align: left;
  white-space: normal;
  margin: 24px 0 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.teams3-wrapper .teams3-top-job,
.teams3-wrapper .teams3-top-content {
  font-size: 12px;
  color: #919191;
}
.teams3-wrapper .teams3 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 20px 0;
  min-height: 456px;
  margin-top: 48px;
}
.teams3-wrapper .teams3 .block-wrapper .block {
  margin-bottom: 48px;
  vertical-align: text-top;
}
.teams3-wrapper .teams3 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.teams3-wrapper .teams3-image {
  color: #404040;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  overflow: hidden;
}
.teams3-wrapper .teams3-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.teams3-wrapper .teams3-textWrapper {
  padding-left: 16px;
}
.teams3-wrapper .teams3-title {
  font-size: 18px;
  margin-bottom: 2px;
}
.teams3-wrapper .teams3-title a {
  color: #000 !important;
}
.teams3-wrapper .teams3-job {
  margin-bottom: 4px;
}
.teams3-wrapper .teams3-job-div {
  text-align: left;
}
.teams3-wrapper .teams3-job,
.teams3-wrapper .teams3-content {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  text-transform: capitalize;
  color: #000000;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.teams3-wrapper .teams3 .teams3-job-span {
  margin-bottom: 42px;
}
.teams3-wrapper .teams3 .teams3-job-tip {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  display: flex;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.44);
  margin-bottom: 6px;
}
.teams3-wrapper .teams3 .teams3-top-content-avatar {
  width: 48px;
  height: 48px;
}
@media screen and (max-width: 767px) {
  .teams3-wrapper {
    min-height: 1440px;
  }
  .teams3-wrapper .teams3.home-page {
    padding-bottom: 0;
    padding: 24px;
  }
  .teams3-wrapper .teams3-job {
    font-size: 12px ;
    line-height: 22px;
  }
  .teams3-wrapper .teams3-textWrapper {
    padding-left: 0;
  }
  .teams3-wrapper .teams3 .teams3-job-tip {
    margin-top: 20px;
  }
  .teams3-wrapper .teams3 .teams3-top-content-avatar {
    width: 38px;
    height: 38px;
  }
  .teams3-wrapper .teams3 .teams3-top-content-text {
    font-size: 13px;
  }
  .teams3-wrapper .teams3-top-title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 6px;
  }
  .teams3-wrapper .teams3-top-title a {
    color: #000 !important;
  }
  .teams3-wrapper .teams3 .teams3-job-span {
    margin-bottom: 18px;
  }
  .teams3-wrapper .teams3-top-job {
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 18px;
    margin-top: 0;
  }
}
#Teams3_0 .ant-row > .ant-col > .l3mufki7ho-editor_css {
  overflow: hidden;
  width: auto;
  height: 360px;
  margin-bottom: 26px;
  border-radius: 12px;
}
#Teams3_0 .ant-row > .ant-col > .l3muhop3pkf-editor_css {
  overflow: hidden;
  max-width: 624px;
  width: 100%;
  height: auto;
  max-height: 355px;
}
#Teams3_0 .ant-col > .teams3-textWrapper > .l3muitic92j-editor_css {
  position: absolute;
}
#Teams3_0 .ant-col > .teams3-textWrapper > .l3mujk43ofb-editor_css {
  position: relative;
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 120%;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 24px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  border-radius: 12px;
}
#Teams3_0 .home-page > .title-wrapper > .l3mujrvlabs-editor_css {
  text-align: left;
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 125%;
  color: #000000;
  margin-bottom: 32px;
}
#Teams3_0 .home-page > .title-wrapper > .l3mujyr5gy-editor_css {
  text-align: left;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 80px;
}
@media screen and (max-width: 1200px) {
  #Teams3_0 .ant-row > .ant-col > .l3mufki7ho-editor_css {
    max-height: 218px;
  }
}
@media screen and (max-width: 767px) {
  #Teams3_0 .home-page > .title-wrapper > .l3mujrvlabs-editor_css {
    font-size: 24px;
    margin-bottom: 12px;
  }
  #Teams3_0 .home-page > .title-wrapper > .l3mujyr5gy-editor_css {
    margin-bottom: 56px;
    font-size: 12px;
    line-height: 12px;
  }
  #Teams3_0 .ant-row > .ant-col > .l3muhop3pkf-editor_css {
    overflow: hidden;
    width: 100%;
    height: 174px;
  }
  #Teams3_0 .ant-col > .teams3-textWrapper > .l3mujk43ofb-editor_css {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 6px;
  }
  #Teams3_0 .ant-row > .ant-col > .l3mufki7ho-editor_css {
    width: 100%;
    height: 174px !important;
    margin-bottom: 0;
    object-fit: cover;
  }
  #Teams3_0 .ant-row > .ant-col > .l3mufki7ho-editor_css img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
