@import '~antd/lib/style/themes/default.less';
@rate: 1.406;
@line-color: #e9e9e9;

@shadow-color: rgba(0, 0, 0, 0.15);

@bottom-bar-bg-color: #262626;
@bottom-bar-line-color: #000;

@template-bg-color: #001529;
@template-bg-color-light: #ececec;
@template-nav-bg-color: #001529;
@template-text-color: #ccc;
@template-text-title-color: #bcbcbc;
@template-text-color-light: #fff;
@template-footer-text-color: #999;

@animate-duration: 0.45s;

/* 详细页图片或框框的样式;
*/
.research {
  min-height: 100vh;
  background-color: #fff;
  padding: 51px 0;
  max-width: 1290px;
  margin:auto
}
.page-shadow() {
  box-shadow: 0 5px 8px @shadow-color;
}

.page-pro() {
  border-radius: 6px;
  border: 1px solid @line-color;
  transform: translateY(0);
  transition: transform 0.3s @ease-out, box-shadow 0.3s @ease-out;
  &:hover {
    .page-shadow();
    transform: translateY(-5px);
  }
}

.research-list-item-img {
  width: calc(35.3125rem / @rate);
  height: calc(16.9375rem / @rate);
  flex-shrink: 0;
  overflow: hidden;
  border-radius: calc(1.25rem / @rate);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.research-list-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: calc(1.25rem / @rate);
  background: rgba(96, 96, 96, 0.1);
  width: 100%;
  padding: calc(1.31rem / @rate) calc(2.25rem / @rate) calc(1.31rem / @rate)
    calc(4.19rem / @rate);
    margin-bottom: calc(1.94rem / @rate);
}

.research-list-item-icon {
  width: calc(3.9375rem / @rate);
  height: calc(3.9375rem / @rate);
  background-image: url('../../../public/home/file.png');
  background-size: cover;
  background-position: center;
  margin-right: calc(1.25rem / @rate);
}
.research-list-item:hover {
  background: rgba(196, 90, 72, 0.3);
  .research-list-item-title {
    // border-bottom: 4px solid #c55a48;
    color: #c55a48;
    font-weight: 700;
  }
  .research-list-item-time {
    color: #c55a48;
  }
}

.research-list-item:active {
  background: rgba(196, 90, 72, 0.3);
  .research-list-item-title {
    // border-bottom: 4px solid #c55a48;
    color: #c55a48;
    font-weight: 700;
  }
  .research-list-item-time {
    color: #c55a48;
  }
}
.research-list-item-title {
  color: #606060;
  font-family: Optima;
  font-size: calc(2.25rem / @rate);
  // font-style: italic;
  font-weight: 700;
  line-height: calc(3.4375rem / @rate); /* 333.333% */
  border-bottom: 4px solid rgba(255, 254, 254, 0);
}

.research-list-item-time {
  color: #606060;
  font-family: Optima;
  font-size: calc(2rem / @rate);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  line-height: calc(4.25rem / @rate); /* 333.333% */
}

.research-list-item-text {
  display: flex;
}

.research-list-title {
  font-family: Inter-Regular;
  font-weight: 500;
  font-size: 48px;
  color: #C45B48;
  line-height: 62px;
  text-align: center;
  font-style: normal;
  text-transform: none;
  text-align: left;
  margin-bottom: 7px;
}
  .research-list-title_tip{
  font-family: Inter-Regular;
  font-weight: 400;
  font-size: 18px;
  color: #292929;
  line-height: 29px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-bottom: 25px;
  }
  .research-list-item-right-time{

    font-family: Inter-Regular;
    font-weight: 400;
    font-size: 13px;
    color: #A1A1A1;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .research-list-item-left-img{
    width: 697px;
    height: 542px;
    object-fit: cover;
  }
  .research-list-item-left{
    width: 697px;
    flex-shrink: 0;
    margin-right: 35px;
  }
  .research-list-block{
    display: flex;
    justify-content: center;
  
  }
  .research-list-item-left-info{
    border: 1px solid #EBEBEB;
    width: 697px;
    padding: 36px;
  }
  .research-list-item-left-tag{
    display:inline-block;
    padding:0 8px;
    font-family: Inter-Regular;
    font-weight: 500;
    font-size: 11px;
    color: #676599;
    line-height: 17px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    background: rgba(103,101,153,0.1);
    margin-right: 10px;
  }

  .research-list-item-left-time{
      font-family: Inter-Regular;
      font-weight: 400;
      font-size: 14px;
      color: #A1A1A1;
      line-height: 16px;
      text-align: left;
      font-style: normal;
      text-transform: none;
  }
  .research-list-item-left-row{
    margin-bottom: 22px;
  
  }
  .research-list-item-left-title{
    font-family: Inter-Medium;
    font-weight: 500;
    font-size: 39px;
    color: #292929;
    line-height: 52px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .research-list-item-right-tip{
    font-family: Inter-Medium;
    font-weight: 500;
    font-size: 21px;
    color: #292929;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .research-list-item-right-item{
    min-height: 168px;
    padding: 25px 0 26px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EBEBEB;
  }
  .research-list-item-right-title{
    font-family: Inter-Medium;
    font-weight: 500;
    font-size: 18px;
    color: #292929;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
  .research-list-item-right-img{
    width: 151px;
    height:117px;
    object-fit: cover;
  }

@media screen and (max-width: 767px) {
.research {
  padding: 24px;
}
.research-list-title{
  font-size:24px;
  margin-bottom: 24px;
}
.research-list-item{
  flex-direction: column;
  padding: 12px;
}
.research-list-item-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 5px;
}
.research-list-item-title {
  font-size: 18px;
  line-height: 20px;
}
.research-list-item-time {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
}
.research-list-item-img{
  width: calc(100vw - 48px - 24px);
  height: calc((100vw - 48px - 24px) / 2.08);
}

}