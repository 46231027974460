.philosophy {
   &-content {
      margin-bottom: 80px
   }

   &-title {
      color: var(--Style, #826AC8);
      font-family: Inter;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      /* 93.333% */
      text-transform: capitalize;
      margin-bottom: 38px;
   }

   &-block {
      display: flex;
      margin-bottom: 40px;

      &_bg {
         width: 326px;
         height: 260px;
         flex-shrink: 0;
         position: absolute;
         right: -20px;
         top: -20px;
         opacity: 0;
         transition: opacity 0.4s ease;

      }

      &_item {
         width: 360px;
         height: 360px;
         border-radius: 16px;
         background: #FFF;
         margin-right: 40px;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         cursor: pointer;
         transition: width 0.5s ease;
         overflow: hidden;
         position: relative;
      }

      &_logos {
         width: 354px;
         position: absolute;
         left: 0;
         transform: translateY(180%) translateX(0%);
         opacity: 0;
         transition: transform 0.5s ease, opacity 0.5s ease;

         &_block {
            display: flex;
            justify-content: space-between;
            align-items: center;
         }

         img {
            width: 354px;
            height: auto;
            margin-right: 40px;
         }
      }

      &_tip {
         color: var(--d-9-d-9-d-9, #333);
         font-family: Inter;
         font-size: 16px;
         font-style: normal;
         font-weight: 300;
         line-height: normal;
         margin-top: 29px;
      }

      &_item:hover {
         width: 640px !important;

         .philosophy-block_bg {
            opacity: 1;
         }

         .philosophy-block_logos {
            transform: translateY(20%) translateX(46px);
            opacity: 1;
            width: 260px;
            img{
            width: 260px;
            }
         }

      }



      .a {
         border: 1px solid var(--03, #5E3290);
         box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

         .philosophy-block_bg {
            background-image: url('../../../public/agix/Ellipse19.png');
            background-size: 100% 100%;
         }
      }

      .a:hover {
         .philosophy-block_icon {
            transform: translateY(-100%) translateX(-355%);
         }

         .philosophy-block_name {
            transform: translateY(-150px) translateX(-90px);
            color: #5E3290;
         }

         .philosophy-block_desc {
            transform: translateY(-170px) translateX(-34px);
         }
      }

      .b {
         border: 1px solid #96B05A;
         box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
         border-radius: 16px;

         .philosophy-block_bg {
            background-image: url('../../../public/agix/Ellipse18.png');
            background-size: 100% 100%;
         }
      }

      .b:hover {
         .philosophy-block_icon {
            transform: translateY(-100%) translateX(-355%);
         }

         .philosophy-block_name {
            transform: translateY(-150px) translateX(-120px);
            color: #96B05A;
         }

         .philosophy-block_desc {
            transform: translateY(-170px) translateX(-91px);
         }
      }

      .c {
         border: 1px solid var(--AGIX-02, #ED8F4B);
         box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
         margin-right: 0 !important;

         .philosophy-block_bg {
            background-image: url('../../../public/agix/Ellipse25.png');
            background-size: 100% 100%;
         }

      }

      .c:hover {
         .philosophy-block_icon {
            transform: translateY(-100%) translateX(-345%);
         }

         .philosophy-block_name {
            transform: translateY(-150px) translateX(-85px);
            color: #ED8F4B;
         }

         .philosophy-block_desc {
            transform: translateY(-170px) translateX(-80px);
         }
      }

      &_icon {
         width: 60px;
         height: 60px;
         margin-bottom: 30px;
         transition: transform 0.5s ease;
      }


      &_name {
         color: var(--c-45-a-48, #C45A48);
         text-align: center;
         font-family: Inter;
         font-size: 26px;
         font-style: normal;
         font-weight: 700;
         line-height: normal;
         margin-bottom: 16px;
         transition: transform 0.5s ease, color 0.5s ease;
      }

      &_desc {
         color: var(--d-9-d-9-d-9, #333);
         text-align: center;
         font-family: "Fira Code";
         font-size: 18px;
         font-style: normal;
         font-weight: 400;
         line-height: 30px;
         transition: transform 0.5s ease, display 0.5s ease;
         width: 300px;
         /* 166.667% */
      }
   }

   &-tip {
      padding: 20px;
      background-color: rgba(234, 221, 207, 0.43);
      border-radius: 16px;
      margin-bottom: 25px;

      &1 {
         color: var(--Style, #826AC8);
         font-family: Inter;
         font-size: 30px;
         font-style: normal;
         font-weight: 800;
         line-height: 28px;
         /* 93.333% */
         text-transform: capitalize;
         margin-bottom: 16px;
      }

      &2 {
         color: #434343;
         font-family: Inter;
         font-size: 20px;
         font-style: normal;
         font-weight: 400;
         line-height: 30px;
         /* 150% */
      }
   }

   &-quadrant {
      position: relative;
      margin-bottom: 68px;

      &_row {
         display: flex;
         margin-bottom: 20px;
      }

      &_item {
         background: #F5F5F5;
         height: 314px;
         width: calc(50% - 10px);
         position: relative;
         margin-left: 20px;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         cursor: pointer;
         transition: width 0.4s ease;
         overflow: hidden;

         .block_0 {
            left: -100%;
         }

         .block_1 {
            right: -100%;
         }

         .block_2 {
            left: -100%;
         }

         .block_3 {
            right: -100%;
         }
      }

      &_item:hover {
         .philosophy-quadrant_itembgdesc {
            background: #DFD7F1;

         }

         .block_0 {
            transform: translateX(100%);
         }

         .block_1 {
            transform: translateX(-100%);
         }

         .block_2 {
            transform: translateX(100%);
         }

         .block_3 {
            transform: translateX(-100%);
         }

         .philosophy-quadrant_name {
            background: var(--Style, #fff);
            color: var(--ffffff, #5E3290);

         }
      }

      &_name {
         background: var(--Style, #826AC8);
         padding: 10px;
         width: 116px;
         height: 50px;
         color: var(--ffffff, #FFF);
         /* 代码字符 */
         font-family: "Fira Code";
         font-size: 20px;
         font-style: normal;
         font-weight: 400;
         line-height: 30px;
         /* 150% */
         position: absolute;
         text-align: center;
         z-index: 8;

         transition: background 0.4s ease, color 0.4s ease;
      }

      &_itembgdesc {
         position: absolute;
         height: 314px;
         width: 100%;
         z-index: 7;
         background: none;
         top: 0;
         // transition: background 0.4s ease, transform 0.4 ease-in-out;
         transition: background 0.6s ease, transform 0.4s ease;
         color: var(--d-9-d-9-d-9, #333);
         font-family: Inter;
         font-size: 20px;
         font-style: normal;
         font-weight: 600;
         line-height: 28px; /* 140% */
         padding: 86px 30px;

      }

      .left-top {
         left: 20px;
         top: 20px;
      }

      .left-bottom {
         left: 20px;
         bottom: 20px;
      }

      .right-top {
         right: 20px;
         top: 20px;
      }

      .right-bottom {
         right: 20px;
         bottom: 20px;
      }

      .philosophy-quadrant_line1 {
         position: absolute;
         background-image: url('../../../public/agix/Line3.png');
         background-size: 100% 100%;
         bottom: -16px;
         left: 0;
         width: 10px;
         height: calc(100% + 20px);
      }

      .philosophy-quadrant_line1_text {
         position: absolute;
         left: -65px;
         top: 50%;
         color: #000;
         font-family: "Fira Code";
         font-size: 20px;
         font-style: normal;
         font-weight: 500;
         line-height: 30px;
         /* 150% */
         transform: rotate(-90deg);
      }

      .philosophy-quadrant_line2_text {
         color: #000;
         position: absolute;
         bottom: -50px;
         left: 50%;
         margin-left: -30px;
         font-family: "Fira Code";
         font-size: 20px;
         font-style: normal;
         font-weight: 500;
         line-height: 30px;
         /* 150% */
      }

      .philosophy-quadrant_line2 {
         position: absolute;
         background-image: url('../../../public/agix/Line4.png');
         background-size: 100% 100%;
         left: 4px;
         bottom: -20px;
         height: 10px;
         width: calc(100% + 10px);
      }
   }

   &-end {
      margin-bottom: 50px;

      &_row {
         display: flex;
         margin-bottom: 20px;

         &_title {
            color: #434343;
            font-family: "Fira Code";
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            /* 150% */
            white-space: nowrap;
            flex-shrink: 0;
            flex-basis: 150px;
         }

         &_desc {
            color: #434343;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            /* 187.5% */
         }
      }
   }
}

@media screen and (max-width: 1500px) {
   .philosophy-block_item{
        margin-right: 20px;
   }
   .philosophy-block_logos{
      width: 260px;
      img{
      width: 260px;
      }
   }
   .philosophy-block_desc{
      font-size: 14px;
   }
   .philosophy-block .a:hover .philosophy-block_icon{
      transform: translateY(-100%) translateX(-355%);
   }
   .philosophy-block .a:hover .philosophy-block_name{
      transform: translateY(-150px) translateX(-90px);
   }
   .philosophy-block .a:hover .philosophy-block_desc{
      transform: translateY(-170px) translateX(-61px);

   }
   .philosophy-block_item:hover .philosophy-block_logos{
      transform: translateY(15%) translateX(46px);
   }
   .philosophy-block_logos img{
      margin-right: 15px;
   }

   .philosophy-block .b:hover .philosophy-block_icon{
      transform: translateY(-100%) translateX(-355%);
   }
   .philosophy-block .b:hover .philosophy-block_name{
      transform: translateY(-150px) translateX(-120px);
   }
   .philosophy-block .b:hover .philosophy-block_desc{
      transform: translateY(-170px) translateX(-105px);
   }

   .philosophy-block .c:hover .philosophy-block_icon{
      transform: translateY(-100%) translateX(-345%);
   }

   .philosophy-block .c:hover .philosophy-block_name{
      transform: translateY(-150px) translateX(-85px);
   }
   .philosophy-block .c:hover .philosophy-block_desc{
      transform: translateY(-170px) translateX(-90px);
   }
   .philosophy-quadrant_item{
      height: 280px;
      img{
         max-width: 80%;
      }
   }
   .philosophy-quadrant_name{
      width: 100px;
      font-size:16px;
      height: 40px;
      line-height: 40px;
      padding: 0;
   }
   .philosophy-quadrant_itembgdesc{
      padding: 65px 20px;
      font-size: 16px
   }
   .philosophy-tip2{
      font-size: 16px;
   }
}

@media screen and (max-width: 1200px) {
   .philosophy-block_item{
        margin-right: 20px;
   }
   .philosophy-block_logos{
      width: 260px;
      img{
      width: 260px;
      }
   }
   .philosophy-block_desc{
      font-size: 14px;
   }
   .philosophy-block .a:hover .philosophy-block_icon{
      transform: translateY(-100%) translateX(-305%);
   }
   .philosophy-block .a:hover .philosophy-block_name{
      transform: translateY(-150px) translateX(-55px);
   }
   .philosophy-block .a:hover .philosophy-block_desc{
      transform: translateY(-170px) translateX(-15px);

   }
   .philosophy-block_item:hover .philosophy-block_logos{
      transform: translateY(15%) translateX(46px);
   }
   .philosophy-block_logos img{
      margin-right: 15px;
   }

   .philosophy-block .b:hover .philosophy-block_icon{
      transform: translateY(-100%) translateX(-305%);
   }
   .philosophy-block .b:hover .philosophy-block_name{
      transform: translateY(-150px) translateX(-90px);
   }
   .philosophy-block .b:hover .philosophy-block_desc{
      transform: translateY(-170px) translateX(-70px);
   }

   .philosophy-block .c:hover .philosophy-block_icon{
      transform: translateY(-100%) translateX(-305%);
   }

   .philosophy-block .c:hover .philosophy-block_name{
      transform: translateY(-150px) translateX(-60px);
   }
   .philosophy-block .c:hover .philosophy-block_desc{
      transform: translateY(-170px) translateX(-65px);
   }
   .philosophy-quadrant_item{
      height: 280px;
      img{
         max-width: 80%;
      }
   }
   .philosophy-quadrant_name{
      width: 100px;
      font-size:16px;
      height: 40px;
      line-height: 40px;
      padding: 0;
   }
   .philosophy-quadrant_itembgdesc{
      padding: 65px 20px;
      font-size: 16px
   }
   .philosophy-tip2{
      font-size: 16px;
   }
}
@primary-color: #A01E28;