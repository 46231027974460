@content0: content0;

.@{content0}-wrapper {
  min-height: 446px;
  position: relative;
  overflow: inherit !important;
  &-img {
    position: absolute;
    width: 100%;
    bottom: -30px;
    z-index: 99;
    height: 150px;
    object-fit: cover;
    img{
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  .@{content0} {
    height: 100%;
    padding: 64px 24px;
    position: relative;
    z-index: 9;
    > .title-wrapper {
      margin: 0 auto 48px;
    }

    &-block {
      padding: 0;
      display: inline-block;
      text-align: center;
      min-height: 200px;
      margin-bottom: 24px;
      img {
        width: 100%;
      }

      &-wrapper {
        position: relative;
        height: 100%;
        top: 25%;
        padding: 20px 0;
      }

      &.queue-anim-leaving {
        position: relative !important;
      }

      &-icon {
        width: 112px;
        height: 102px;
        margin: auto;
      }

      &-title {
        line-height: 32px;
        margin: 32px auto;
        font-size: 20px;
        font-family: 'HK Grotesk';
        font-style: normal;
        font-weight: 500;
        line-height: 144%;
        text-align: center;
        color: #000000;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content0}-wrapper {
    // min-height: 880px;
    .home-page {
      padding:0 24px !important;
      position: relative;
      z-index: 999;
    }
    .@{content0} {
      > .title-wrapper {
        font-size: 24px;
line-height: 24px;
      }
      &-block{
        min-height:132px;
        &-icon {
          width: 94px;
          height: 94px;
        }
        &-title{
          font-size: 12px !important;
          line-height: 18px !important;
          margin-top: 0 !important;
        }
      }
    }
    
  }
}
