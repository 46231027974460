@import '~antd/lib/style/themes/default.less';

@line-color: #e9e9e9;

@shadow-color: rgba(0, 0, 0, 0.15);

@bottom-bar-bg-color: #262626;
@bottom-bar-line-color: #000;

@template-bg-color: #001529;
@template-bg-color-light: #ececec;
@template-nav-bg-color: #001529;
@template-text-color: #ccc;
@template-text-title-color: #bcbcbc;
@template-text-color-light: #fff;
@template-footer-text-color: #999;

@animate-duration: 0.45s;

/* 详细页图片或框框的样式;
*/
.page-shadow() {
  box-shadow: 0 5px 8px @shadow-color;
}

.templates-wrapper {
  position: relative;
}

.page-pro() {
  border-radius: 6px;
  border: 1px solid @line-color;
  transform: translateY(0);
  transition: transform 0.3s @ease-out, box-shadow 0.3s @ease-out;

  &:hover {
    .page-shadow();
    transform: translateY(-5px);
  }
}

// @rate: 1;
@rate: 1.406;

.keyword-wrapper {
  max-width: 100% !important;
  height: calc(100vh - 64px) !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.keyword-item {
  color: #c55a48;
  text-align: center;
  font-family: Optima;
  font-size: calc(4.5rem / @rate);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.keyword-line {
  display: flex;
  justify-content: space-around;
  height: calc(0.625rem / @rate);
  width: 100%;
  background: linear-gradient(
    270deg,
    rgba(197, 90, 72, 0) 0%,
    rgba(197, 90, 72, 0.7) 13.21%,
    #c55a48 52.6%,
    rgba(197, 90, 72, 0.7) 88.04%,
    rgba(197, 90, 72, 0) 100%
  );
}

.keyword-block {
  width: calc(100vw / @rate);
  display: flex;
  justify-content: center;
  position: relative;
}

.keyword-block-img {
  width: calc(120px / @rate);
  height: calc(120px / @rate);
  background-image: url('../../../public/home/circle.png');
  background-size: 100% 100%;
  // background-size: cover;
  // background-attachment: fixed;
  // background-position: center;
  margin-top: calc(((60px / @rate) * -1) + (0.625rem / @rate / 2));
}

.keyword-content0-block {
  min-height: auto !important;
}

.keyword-item-desc {
  max-width: calc(26.9375rem / @rate);
  color: #c55a48;
  text-align: justify;
  font-family: Optima;
  font-size: calc(1.5rem / @rate);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 40px auto;
}

.research-title {
  color: #fff;
  text-align: center;
  font-family: Optima;
  font-size: calc(6.25rem / @rate);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: calc(4.4rem / @rate);
  text-align: left;
}

.research-item-icon {
  width: calc(3.75rem / @rate);
  height: calc(3.75rem / @rate);
  background-image: url('../../../public/home/file.png');
  background-size: cover;
  background-position: center;
  margin-right: 16px;
  flex-shrink: 0;
  margin-top: 5px;
}

.research-item {
  margin-bottom: 34px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

// .research-item-line {
//   height: 2px;
//   background-color: #fff;
//   width: 100%;
//   opacity: 0;
//   margin-top: -20px;
// }
.research-item-title-it {
  border-bottom: 2px solid #ffffff00;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  padding: 0 10px 0 0;
}

.research-item:hover {
  .research-item-title-it {
    border-color: #fff;
  }
  // .research-item-line {
  //   opacity: 1 !important;

  // }

  .research-item-title {
    font-weight: 700;
  }
}

.research-item-title {
  color: #fff;
  font-family: Optima;
  font-size: calc(2.25rem / @rate);
  font-style: italic;
  font-weight: 400;
  // line-height: calc(7.5rem / @rate);
  /* 333.333% */
  height: calc(7.5rem / @rate);
  overflow: hidden;

  text-overflow: ellipsis;

  display: -webkit-box;

  -webkit-box-orient: vertical;

  -webkit-line-clamp: 2;
}

.research-wrapper {
  background-image: none !important;
  // background-attachment: fixed;
  overflow: hidden !important;
  min-height: 100vh;
  background-color: #c55a48;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.research-wrapper-img {
  position: absolute;
  width: 100%;
  height: calc(30.41819rem / @rate);
  top: 50%;
  margin-top: calc((30.41819rem / @rate / 2) * -1);
  background-position: center;
  background-size: auto calc(30.41819rem / @rate);
  background-size: 100% 100%;
  background-image: url('../../../public/home/bg2.png');
}

.research-img {
  width: calc(66.0625rem / @rate);
  height: calc(47.1875rem / @rate);
  background-position: center;
  background-size: auto calc(30.41819rem / @rate);
  background-size: 100% 100%;
  background-image: url('../../../public/home/space.png');
}

.research-item-btn {
  width: calc(15.875rem / @rate);
  height: calc(4.1875rem / @rate);
  border-radius: 0.3125rem;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  text-align: center;
  font-family: Optima;
  font-size: calc(2rem / @rate);
  font-style: normal;
  font-weight: 700;
  line-height: calc(4.1875rem / @rate);
  margin-top: calc(3.75rem / @rate);
  cursor: pointer;
}

.project-block-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(197, 90, 72, 0.3);
  z-index: 10;
}

.project-block {
  display: flex;
  width: 100%;
}

.project-item {
  flex: 1;
  height: calc(100vh - 64px);
  margin-left: -20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bytedance {
  background-size: 100% 100%;
  background-image: url('../../../public/home/bytedance_bg.png');
  z-index: 9;
}

.bytedance-logo {
  width: calc(29.8125rem / @rate);
  height: calc(9rem / @rate);
  background-size: 100% 100%;
  background-image: url('../../../public/home/bytedance_logo.png');
  z-index: 11;
}

.spacex {
  background-size: 100% 100%;
  background-image: url('../../../public/home/spacex_bg.png');
  z-index: 8;
}

.spacex-logo {
  width: calc(29.625rem / @rate);
  z-index: 11;
  height: calc(7.8125rem / @rate);
  background-size: 100% 100%;
  background-image: url('../../../public/home/spacex_logo.png');
}

.epic_games {
  background-size: 100% 100%;
  background-image: url('../../../public/home/epic_bg.png');
  z-index: 7;
}

.epic_games-logo {
  width: calc(15rem / @rate);
  z-index: 11;
  height: calc(15.625rem / @rate);
  background-size: 100% 100%;
  background-image: url('../../../public/home/epic_logo.png');
}

.discord {
  background-size: 100% 100%;
  background-image: url('../../../public/home/disco_bg.png');
  z-index: 6;
}

.discord-logo {
  width: calc(33.75rem / @rate);
  z-index: 11;
  height: calc(11.75rem / @rate);
  background-size: 100% 100%;
  background-image: url('../../../public/home/disco_logo.png');
}

.project-block-notice {
  width: 100vw;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: 0;
  z-index: 12;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(15rem / @rate);
  &-title {
    color: #c55a48;
    text-align: center;
    font-family: Optima;
    font-size: calc(3.75rem / @rate);
    font-style: normal;
    font-weight: 700;
    line-height: calc(3.75rem / @rate); /* 100% */
  }

  &-desc {
    color: #c55a48;
    font-family: Optima;
    font-size: calc(2.25rem / @rate);
    font-style: normal;
    font-weight: 700;
    line-height: calc(3.75rem / @rate);
  }

  &-tip {
    color: rgba(96, 96, 96, 0.75);
    text-align: right;
    font-family: Optima;
    font-size: calc(0.75rem / @rate);
    font-style: normal;
    font-weight: 400;
    line-height: calc(1.875rem / @rate); /* 250% */
    position: absolute;
    bottom: calc(0.44rem / @rate);
    right: calc(1rem);
  }
}

.connect {
  background: #c55a48;
  height: calc(100vh - 72px) !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.connect-block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - (20rem / @rate));
  position: absolute;
  top: 0;

  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */

    color: #fff !important;
    text-align: center;
    font-family: Optima;
    font-size: calc(1.875rem / @rate);
    font-weight: 400;
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
    text-align: center;
    font-family: Optima;
    font-size: calc(1.875rem / @rate);
    font-weight: 400;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
    text-align: center;
    font-family: Optima;
    font-size: calc(1.875rem / @rate);
    font-weight: 400;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
    text-align: center;
    font-family: Optima;
    font-size: calc(1.875rem / @rate);
    font-weight: 400;
  }
}

.connect-title {
  color: #fff;
  text-align: center;
  font-family: Optima;
  font-size: calc(6.25rem / @rate);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.connect-desc {
  color: #fff;
  text-align: center;
  font-family: Optima;
  font-size: calc(1.875rem / @rate);
  font-style: normal;
  font-weight: 400;
  line-height: calc(7.5rem / @rate); /* 400% */
}

.connect-input {
  width: calc(57.5rem / @rate) !important;
  height: calc(6.25rem / @rate) !important;
  border-radius: 0.3125rem;
  background: rgba(255, 255, 255, 0.3);
  width: calc(11.25rem / @rate);
  height: calc(4.1875rem / @rate);
  text-align: center;
  border: 0;
  color: #fff;
  font-family: Optima;
  font-size: calc(1.875rem / @rate);
  font-weight: 400;
}
.connect-tip {
  position: absolute;
  height: 50px;
  width: 220px;
  background-image: url('../../../public/home/tip.png');
  background-size: 100% 100%;
  top: 50%;
  right: 10px;
  margin-top: -25px;
  color: #c55a48;
  font-family: Optima;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  line-height:50px;
  padding-left: 10px;
}
.connect-btn {
  width: 300px;
  height: 66px;
  border-radius: 0.3125rem;
  background: #fff;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  color: #c55a48;
  justify-content: center;
  text-align: center;
  font-family: Optima;
  font-size: calc(2.5rem / @rate);
  font-style: normal;
  font-weight: 700;
  line-height: 66px;
  margin-top: calc(6.31rem / @rate);
  cursor: pointer;
}

.connect-footer-logo {
  width: calc(6.85881rem / @rate);
  height: calc(2.32475rem / @rate);
  flex-shrink: 0;
  background-image: url('../../../public/home/fotterlogo.png');
  background-size: 100% 100%;
}

.connect-footer-in {
  width: calc(3.0625rem / @rate);
  height: calc(3.0625rem / @rate);
  flex-shrink: 0;
  background-image: url('../../../public/home/in.png');
  background-size: 100% 100%;
}

.connect-footer-x {
  width: calc(1.75rem / @rate);
  height: calc(1.5625rem / @rate);
  margin: 0 5px 0 10px;
  flex-shrink: 0;
  background-image: url('../../../public/home/x.png');
  background-size: 100% 100%;
}

.logo-div {
  line-height: calc(5.875rem / @rate); /* 300% */
  height: calc(5.875rem / @rate); /* 300% */
  position: absolute;
  top: calc(3.2rem / @rate);
  left: calc(5rem / @rate);
}
.logo-img {
  width: calc(18.87275rem / @rate);
  height: calc(3.09956rem / @rate);
}

.link {
  color: #fff;
  font-family: Optima;
  font-size: calc(2.5rem / @rate);
  font-style: normal;
  font-weight: 400;
  line-height: calc(5.875rem / @rate); /* 300% */
  height: calc(5.875rem / @rate);
  white-space: nowrap;
}


.connect-footer {
  background: #7e3a2e;
  height: calc(15rem / @rate);
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 calc(5rem / @rate);
  align-items: center;
}

.connect-footer-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #fff;
  font-family: Optima;
  font-size: calc(1.5rem / @rate);
  font-style: normal;
  font-weight: 400;
  margin-top: 16px;
}

.sha {
  animation: blink 1s step-start infinite;
}
.mast-point {
  width: 1.25rem;
  height: 2.25rem;
  margin-left: 5px;
  flex-shrink: 0;
  background: #c55a48;
  display: inline-block;
  opacity: 0; /* 初始状态为完全透明 */
  animation: fadeIn 1s ease-in-out 3s forwards, blink 1s step-end infinite; /* 使用fadeIn动画，2秒后开始，持续1秒，使用ease-in-out过渡效果，然后应用闪动动画 */
  animation-delay: 3s;
}

.chat3{
  background: #c55a48;

}

@keyframes fadeIn {
  0% {
    opacity: 0; /* 初始状态为完全透明 */
  }
  100% {
    opacity: 1; /* 最终状态为完全可见 */
  }
}

/* 定义一个光标闪动的关键帧 */
@keyframes blink {
  0% {
    opacity: 1; /* 初始状态为完全可见 */
  }
  50% {
    opacity: 0; /* 中间状态为完全透明 */
  }
  100% {
    opacity: 1; /* 最终状态为完全可见 */
  }
}

.point {
  color: #c55a48;
  text-align: center;
  font-family: Optima;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media screen and (max-width: 1500px) {
  .banner-chat{
    padding: 20px !important;
  }
  .connect-title{
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 1200px) {
  .banner-chat{
    padding: 20px !important;
  }
  .connect-title{
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 767px) {

  .banner-chat{
    padding: 20px !important;
  }

  .menu-btn {
    width: calc(11.25rem / 1.5 / @rate);
    height: calc(4.1875rem / 1.5 / @rate);
    font-size: calc(1.75rem / 1.5 / @rate);
    line-height: calc(4.1875rem / 1.5 / @rate);
  }

  .research-img {
    width: calc(100vw - 60px);
    height: calc((100vw - 60px) / 1.4);
  }
  .research-title {
    font-size: 24px;
  }
  .research-item {
    align-items: flex-start;
    margin-bottom: 48px;
  }
  .research-item-icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
  .research-item-title {
    height: auto;
    font-size: 20px;
    line-height: 24px;
  }
  .research-item-btn {
    width: 100%;
  }
  .project-block {
    flex-direction: column;
  }
  .project-item {
    flex: none;
    width: 100%;
    margin: 0;
    height: 160px;
    background-size: auto;
    background-position: center;
  }
  .connect-title {
    font-size: 32px;
  }
  .connect-desc {
    font-size: 14px;
    line-height: 24px;
    margin: 10px 0 20px;
  }

  .connect-input {
    width: 300px !important;
    height: 40px !important;
    font-size: 16px;
  }

  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */

    font-size: 16px;
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-size: 16px;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-size: 16px;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 16px;
  }

  .connect-btn {
    width: 200px !important;
    font-size: 16px !important;
    height: 40px !important;
  }

  .connect-footer-desc {
    font-size: 12px;
    margin: 0;
  }
  .connect-footer {
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
  }
  .connect-footer-logo {
    margin-bottom: 10px;
  }
  .epic_games-logo {
    z-index: 11;
    width: 70px;
    height: calc(70px / 0.96);
  }

  .discord-logo {
    width: 200px;
    height: calc(200px / 2.87);
  }

  .spacex-logo {
    width: 200px;
    height: calc(200px / 3.972);
  }

  .bytedance-logo {
    width: 200px;
    height: calc(200px / 3.3125);
  }
  .project-block-notice-title {
    font-size: 24px;
  }

  .project-block-notice-desc {
    font-size: 14px;
  }
  .project-block-notice-tip {
    position: relative;
    text-align: center;
    margin: 0 10px;
    right: auto;
  }
  .project-block-notice {
    height: calc(18rem / 1.406);
  }
}
