@content8: content8;
.@{content8}-wrapper {
  &.home-page-wrapper {
    overflow: initial;
    width: 100%;
    min-height: 878px;
    margin: auto;
    border-radius: 4px;
    // box-shadow: 0 32px 32px rgba(34, 94, 222, 0.08);
    background: #fff;
    & .home-page {
      // margin-top: -220px;
      padding: 64px 24px;
      overflow: hidden;
    }
  }
  .content-wrapper {
    margin-top: 72px;
    .@{content8}-block-wrapper {
      margin-bottom: 60px;
    }
    .@{content8}-block {
      width: 100%;
      max-width: 268px;
      margin: auto;
    }
    .imgcover {
      .content8-card-img {
        img {
          height: 100% !important;
        }
      }
    }
    .content8-card-avatar {
      img {
        height: auto !important;
      }
    }
    .@{content8}-img {
      border-radius: 8px;
      overflow: hidden;
      width: 100%;
      cursor: pointer;
      // color: #242424;
      position: relative;
      transition: transform 0.2s, box-shadow 0.2s, background 0.2s;
      will-change: transform, box-shadow, background;
      .content8-card {
        display: none;
        height: 284px;
        width: 100%;
        padding: 26px 24px;
        .ant-card-body {
          padding: 20px 0;
        }
        .ant-card-meta-title {
          font-family: 'HK Grotesk';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 137.69%;
          color: #000000;
          margin: 0 0 14px 0;
        }
        .ant-card-meta-description {
          max-width: 190px;
          font-family: 'HK Grotesk';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 160%;
          // text-transform: capitalize;
          color: #000000;
        }
      }
      .content8-card-img {
        width: 100%;
        display: block;
        height: 284px;
        object-fit: cover;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          background: #94989b;
        }
        &[src=''] {
          background: #94989b;
          padding-bottom: 100%;
        }
      }
    }
    .@{content8}-img:focus:before,
    .@{content8}-img:hover:before {
      border-color: #242424;
    }
    .@{content8}-img::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      border-radius: inherit;
      border: 1px solid rgba(36, 36, 36, 0.1);
      transition: border-color 0.15s;
      border-color: transparent;
    }
    .@{content8}-img:hover {
      background-color: #fff;
      transform: translate(6px, -6px);
      box-shadow: -3px 3px 0 -1px #ffc5d3, -3px 3px 0 0 #242424,
        -6px 6px 0 -1px #c9a0fc, -6px 6px 0 0 #242424;
      .content8-card-img {
        display: none;
      }
      .content8-card {
        display: block;
      }
    }
    .@{content8}-title {
      font-size: 20px;
      color: #0d1a26;
      font-weight: 400;
      margin: 24px auto 8px;
      text-align: center;
      white-space: nowrap;
    }
    .@{content8}-content {
      text-align: center;
      white-space: nowrap;
      font-size: 14px;
      color: #697b8c;
    }
  }
  .content8-text {
    position: relative;
    span {
      position: relative;
      z-index: 9;
    }
  }
  .content8-text-icon {
    position: absolute;
    width: 145px;
    height: 38px;
    left: 24px;
    top: 14px;
  }
}

@media screen and (max-width: 767px) {
  .@{content8}-wrapper.home-page-wrapper {
    padding-bottom: 0;
    box-shadow: none;
    width: 100%;
    .@{content8} {
      &.home-page {
        margin: auto;
        padding: 24px;
      }
    }
    .content-wrapper {
      margin-top: 36px;
      .@{content8}-block {
        max-width: 240px;
      }
      .@{content8}-img {
        .content8-card {
          // display: none;
          width: 157px;
          height: 165px;
          padding: 12px !important;
          .ant-card-meta-title {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 4px;
          }
          .ant-card-body {
            padding: 10px 0 0;
          }
          .ant-card-meta-description {
            font-size: 9px;
            line-height: 15px;
          }
        }
      }
      .content8-card-img {
        width: 157px;
        height: 165px;
      }
    }
    .@{content8}-title {
      font-size: 14px;
      line-height: 14px;
      margin: 16px auto 8px;
    }
    .content8-text-icon {
      position: absolute;
      width: 115px;
      height: 23px;
      left: 3px;
      top: 5px;
    }
  }
}
