.agixfooter {
    &-content {
        display: flex;
        justify-content: space-between;
    }

    &-left {
        flex: 1;
        background: rgba(161, 161, 161, 0.08);
        display: flex;
        flex-direction: column;
        padding:  46px 0 46px 200px;

        &-title {
            display: flex;
            margin-bottom: 24px;

            &-left {
                color: var(--Style, #826AC8);
                font-family: Optima;
                font-size: 24px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                margin-right: 24px;
            }

            &-right {
                span {
                    color: var(--Style, #826AC8);
                    font-family: Optima;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                img {
                    width: 46.653px;
                    height: 15.813px;
                    flex-shrink: 0;
                    margin-bottom: 5px;
                }
            }
        }

        &-desc {
            color: var(--d-9-d-9-d-9, #333);
            font-family: Optima;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    &-right {
        background: rgba(130, 106, 200, 0.60);
        width: 567px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        padding:  46px 0 46px 5%;

        &-tip {
            display: flex;
            align-items: center;
            margin-bottom: 13px;
            img{
                margin-left: 20px;
                width: 30px;
                height: 30px;
            }
        
        }
    }
 
}
.agixfooter-right_tip {
    color: var(--ffffff, #FFF);
    font-family: Optima;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
@primary-color: #A01E28;