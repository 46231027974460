#Teams4_0 .home-page > .title-wrapper > .l3ltimgvn89-editor_css {
  font-size: 48px;
  line-height: 125%;
  text-align: left;
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  color: #000000;
  margin-bottom: 32px;
}
#Teams4_0 .home-page > .title-wrapper > .l3ltisirm0m-editor_css {
  font-size: 20px;
  line-height: 150%;
  text-align: left;
  max-width: 682px;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #000000;
}
#Teams4_0.l3ltk50g5si-editor_css {
  position: static;
}

@media screen and (max-width: 767px) {
  #Teams4_0 .home-page > .title-wrapper > .l3ltimgvn89-editor_css {
    font-size: 24px;
    line-height: 125%;
  }
  #Teams4_0 .home-page > .title-wrapper > .l3ltisirm0m-editor_css {
    font-size: 12px;
    line-height: 150%;
  }
}