body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.btn {
  width: 50px;
  height: 36px;
  line-height: 36px;
  background: #c55a48;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin: auto;
}
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.content13-wrapper {
  min-height: 380px;
  background: url("https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
}
.content13-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content13-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .content13-wrapper {
    padding-bottom: 0;
  }
}
.content8-wrapper.home-page-wrapper {
  overflow: initial;
  width: 100%;
  min-height: 878px;
  margin: auto;
  border-radius: 4px;
  background: #fff;
}
.content8-wrapper.home-page-wrapper .home-page {
  padding: 64px 24px;
  overflow: hidden;
}
.content8-wrapper .content-wrapper {
  margin-top: 72px;
}
.content8-wrapper .content-wrapper .content8-block-wrapper {
  margin-bottom: 60px;
}
.content8-wrapper .content-wrapper .content8-block {
  width: 100%;
  max-width: 268px;
  margin: auto;
}
.content8-wrapper .content-wrapper .imgcover .content8-card-img img {
  height: 100% !important;
}
.content8-wrapper .content-wrapper .content8-card-avatar img {
  height: auto !important;
}
.content8-wrapper .content-wrapper .content8-img {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  position: relative;
  transition: transform 0.2s, box-shadow 0.2s, background 0.2s;
  will-change: transform, box-shadow, background;
}
.content8-wrapper .content-wrapper .content8-img .content8-card {
  display: none;
  height: 284px;
  width: 100%;
  padding: 26px 24px;
}
.content8-wrapper .content-wrapper .content8-img .content8-card .ant-card-body {
  padding: 20px 0;
}
.content8-wrapper .content-wrapper .content8-img .content8-card .ant-card-meta-title {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 137.69%;
  color: #000000;
  margin: 0 0 14px 0;
}
.content8-wrapper .content-wrapper .content8-img .content8-card .ant-card-meta-description {
  max-width: 190px;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 160%;
  color: #000000;
}
.content8-wrapper .content-wrapper .content8-img .content8-card-img {
  width: 100%;
  display: block;
  height: 284px;
  object-fit: cover;
}
.content8-wrapper .content-wrapper .content8-img .content8-card-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
  background: #94989b;
}
.content8-wrapper .content-wrapper .content8-img .content8-card-img[src=''] {
  background: #94989b;
  padding-bottom: 100%;
}
.content8-wrapper .content-wrapper .content8-img:focus:before,
.content8-wrapper .content-wrapper .content8-img:hover:before {
  border-color: #242424;
}
.content8-wrapper .content-wrapper .content8-img::before {
  content: '';
  display: block;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: inherit;
  border: 1px solid rgba(36, 36, 36, 0.1);
  transition: border-color 0.15s;
  border-color: transparent;
}
.content8-wrapper .content-wrapper .content8-img:hover {
  background-color: #fff;
  transform: translate(6px, -6px);
  box-shadow: -3px 3px 0 -1px #ffc5d3, -3px 3px 0 0 #242424, -6px 6px 0 -1px #c9a0fc, -6px 6px 0 0 #242424;
}
.content8-wrapper .content-wrapper .content8-img:hover .content8-card-img {
  display: none;
}
.content8-wrapper .content-wrapper .content8-img:hover .content8-card {
  display: block;
}
.content8-wrapper .content-wrapper .content8-title {
  font-size: 20px;
  color: #0d1a26;
  font-weight: 400;
  margin: 24px auto 8px;
  text-align: center;
  white-space: nowrap;
}
.content8-wrapper .content-wrapper .content8-content {
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  color: #697b8c;
}
.content8-wrapper .content8-text {
  position: relative;
}
.content8-wrapper .content8-text span {
  position: relative;
  z-index: 9;
}
.content8-wrapper .content8-text-icon {
  position: absolute;
  width: 145px;
  height: 38px;
  left: 24px;
  top: 14px;
}
@media screen and (max-width: 767px) {
  .content8-wrapper.home-page-wrapper {
    padding-bottom: 0;
    box-shadow: none;
    width: 100%;
  }
  .content8-wrapper.home-page-wrapper .content8.home-page {
    margin: auto;
    padding: 24px;
  }
  .content8-wrapper.home-page-wrapper .content-wrapper {
    margin-top: 36px;
  }
  .content8-wrapper.home-page-wrapper .content-wrapper .content8-block {
    max-width: 240px;
  }
  .content8-wrapper.home-page-wrapper .content-wrapper .content8-img .content8-card {
    width: 157px;
    height: 165px;
    padding: 12px !important;
  }
  .content8-wrapper.home-page-wrapper .content-wrapper .content8-img .content8-card .ant-card-meta-title {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 4px;
  }
  .content8-wrapper.home-page-wrapper .content-wrapper .content8-img .content8-card .ant-card-body {
    padding: 10px 0 0;
  }
  .content8-wrapper.home-page-wrapper .content-wrapper .content8-img .content8-card .ant-card-meta-description {
    font-size: 9px;
    line-height: 15px;
  }
  .content8-wrapper.home-page-wrapper .content-wrapper .content8-card-img {
    width: 157px;
    height: 165px;
  }
  .content8-wrapper.home-page-wrapper .content8-title {
    font-size: 14px;
    line-height: 14px;
    margin: 16px auto 8px;
  }
  .content8-wrapper.home-page-wrapper .content8-text-icon {
    position: absolute;
    width: 115px;
    height: 23px;
    left: 3px;
    top: 5px;
  }
}
#Teams4_0 .home-page > .title-wrapper > .l3ltimgvn89-editor_css {
  font-size: 48px;
  line-height: 125%;
  text-align: left;
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  color: #000000;
  margin-bottom: 32px;
}
#Teams4_0 .home-page > .title-wrapper > .l3ltisirm0m-editor_css {
  text-align: left;
  max-width: 682px;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #000000;
}
#Teams4_0.l3ltk50g5si-editor_css {
  position: static;
}
@media screen and (max-width: 767px) {
  #Teams4_0 .home-page > .title-wrapper > .l3ltimgvn89-editor_css {
    font-size: 24px;
    line-height: 125%;
  }
  #Teams4_0 .home-page > .title-wrapper > .l3ltisirm0m-editor_css {
    font-size: 12px;
    line-height: 150%;
  }
}
