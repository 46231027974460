#Teams3_0 .ant-row > .ant-col > .l3mufki7ho-editor_css {
  overflow: hidden;
  // max-width: 540px;
  width: auto;
  height: 360px;
  margin-bottom: 26px;
  border-radius: 12px;
}
#Teams3_0 .ant-row > .ant-col > .l3muhop3pkf-editor_css {
  overflow: hidden;
  max-width: 624px;
  width: 100%;
  height: auto;
  max-height: 355px;
}
#Teams3_0 .ant-col > .teams3-textWrapper > .l3muitic92j-editor_css {
  position: absolute;
}
#Teams3_0 .ant-col > .teams3-textWrapper > .l3mujk43ofb-editor_css {
  position: relative;
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 120%;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 24px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  border-radius: 12px;
}
#Teams3_0 .home-page > .title-wrapper > .l3mujrvlabs-editor_css {
  text-align: left;
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 125%;
  color: #000000;
  margin-bottom: 32px;
}
#Teams3_0 .home-page > .title-wrapper > .l3mujyr5gy-editor_css {
  text-align: left;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 80px;
}

@media screen and (max-width: 1200px) {
  #Teams3_0 .ant-row > .ant-col > .l3mufki7ho-editor_css {
    max-height: 218px;
  }
}
@media screen and (max-width: 767px) {
  #Teams3_0 .home-page > .title-wrapper > .l3mujrvlabs-editor_css {
    font-size: 24px;
    margin-bottom: 12px;
  }
  #Teams3_0 .home-page > .title-wrapper > .l3mujyr5gy-editor_css {
    margin-bottom: 56px;
    font-size: 12px;
    line-height: 12px;
  }
  #Teams3_0 .ant-row > .ant-col > .l3muhop3pkf-editor_css {
    overflow: hidden;
    width: 100%;
    height: 174px;
  }
  #Teams3_0 .ant-col > .teams3-textWrapper > .l3mujk43ofb-editor_css {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 6px;
  }
  #Teams3_0 .ant-row > .ant-col > .l3mufki7ho-editor_css {
    width: 100%;
    height: 174px !important;
    margin-bottom: 0;
    object-fit: cover;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
