@homepage: home-page;
.@{homepage}-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  .@{homepage} {
    height: 100%;
    max-width: 1200px;
    position: relative;
    margin: auto;
    will-change: transform;
    z-index: 999;

  }
  .title-wrapper > h1, > h1 {
    font-size: 32px;
    color: @text-color;
    margin-bottom: 16px;
  }
  .title-wrapper {
    margin: 0 auto 64px;
    text-align: center;
  }
}

.@{homepage} {
  padding: 128px 24px;
}

@media screen and (max-width: 767px) {
  .@{homepage}-wrapper {
    .@{homepage} {
      padding: 56px 24px;
      >h1 {
        font-size: 24px;
        margin: 0 auto 32px;
        &.title-h1 {
          margin-bottom: 8px;
        }
      }
      >p {
        margin-bottom: 32px;
      }
    }
  }
}
