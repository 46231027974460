@homepage: home-page;
.@{homepage}-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  .@{homepage} {
    height: 100%;
    max-width: 1200px;
    position: relative;
    margin: auto;
    will-change: transform;
    z-index: 999;
  }
  .philosophy {
    max-width: 100% !important;
  }
  .title-wrapper > h1,
  > h1 {
    font-size: 32px;
    color: @text-color;
    margin-bottom: 16px;
  }
  .title-wrapper {
    margin: 0 auto 64px;
    text-align: center;
  }
  .content3-icon {
    width: 64px !important;
    height: 64px;
    object-fit: cover;
    margin-bottom: 32px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content3-block {
    display: flex !important;
    padding: 32px 20px 0 !important;
    vertical-align: top;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 230px;
    height: 284px;
    margin-right: 12px;
    border-radius: 12px;
    transition: transform 0.2s, box-shadow 0.2s, background 0.2s;
    will-change: transform, box-shadow, background;
    cursor: pointer;
  }

  .content3-block:focus:before,
  .content3-block:hover:before {
    border-color: #242424;
  }
  .content3-block::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: inherit;
    border: 1px solid rgba(36, 36, 36, 0.1);
    transition: border-color 0.15s;
    border-color: transparent;
  }
  .content3-block:hover {
    background-color: #fff;
    transform: translate(6px, -6px);
    box-shadow: -3px 3px 0 -1px #fff, -3px 3px 0 0 #242424, -6px 6px 0 -1px #fff,
      -6px 6px 0 0 #242424;
  }

  .block1-bg {
    background-color: #fef4e8;
  }
  .block2-bg {
    background-color: #fedcdc;
  }
  .block3-bg {
    background-color: #e3fff3;
  }
  .block4-bg {
    background-color: #e3f8ff;
  }
  .block5-bg {
    background-color: #f1f6e9;
  }
  .block6-bg {
    background-color: #ede7fa;
  }
  .content3-block-wrapper {
    min-width: 1452px;
    margin: 0 5%;
  }
  .line {
    position: absolute;
    left: 0;
  }
  .svg-row,
  .content3-block-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    // position: relative;
    // left: 50%;
    // margin-left: -726px;
    .svg {
      margin: 32px 9px;
      cursor: pointer;
    }
  }

  .content3-text {
    display: inline-block;
    position: relative;
    padding: 0 !important;
    span {
      z-index: 9;
      position: relative;
    }
  }
  .content3-text-icon {
    position: absolute;
    width: 306px;
    height: 46px;
    left: -26px;
    top: 15px;
  }
}

.@{homepage} {
  padding: 128px 24px;
}

@media screen and (max-width: 767px) {
  .@{homepage}-wrapper {
    .@{homepage} {
      padding: 56px 24px;
      > h1 {
        font-size: 24px;
        margin: 0 auto 32px;
        &.title-h1 {
          margin-bottom: 8px;
        }
      }
      > p {
        margin-bottom: 32px;
      }
     
    }
    .philosophy {
      padding: 44px 24px !important;

    }
    .content3-text-icon {
      position: absolute;
      width: 160px;
      height: 29px;
      left: -12px;
      top: 3px;
    }
    .content3-block{
      width: 158px;
height: 190px;
padding: 16px !important;
flex-shrink: 0;
    }

    .content3-icon {
      width: 22px !important;
      height: 22px;
      object-fit: cover;
      margin-bottom: 7px;
    }
    .content3-block-wrapper {
      width: 100%;
      overflow-x: auto;
      min-width:  100%;
      justify-content: flex-start;
      padding: 10px 0
    }
    .line {
      width: 113px;
      left: 24px;
    }
    .svg-row,
  .content3-block-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    // position: relative;
    // left: 50%;
    // margin-left: -726px;
    .svg {
      margin: 12px 9px;
      cursor: pointer;
      width: 22px;
      height: 22px;
    }
  }
    .title-wrapper {
      margin: 0 auto !important;
    }
    .title-wrapper > h1,
  > h1 {
    font-size: 24px !important;
    line-height: 24px;
    margin-bottom: 12px !important;
  }
  }
}
