@import '~antd/dist/antd.less';
@roboto-font-path: './Font';

@font-face {
  font-family: 'Optima';
  src: url('@{roboto-font-path}/optima-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('@{roboto-font-path}/Inter-Regular-9.otf') format('truetype');
}

@font-face {
  font-family: 'Inter-Medium';
  src: url('@{roboto-font-path}/Inter-Medium-8.otf') format('truetype');
}


@font-face {
  font-family: 'Inter-Bold';
  src: url('@{roboto-font-path}/Inter-Bold-4.otf') format('truetype');
}

@font-face {
  font-family: 'Inter-Light';
  src: url('@{roboto-font-path}/Inter-Light-7.otf') format('truetype');
}

.roboto-font(@type, @weight, @style) {
  @font-face {
    font-family: 'Roboto';
    src: url('@{roboto-font-path}/TTF/HKGrotesk-@{type}.ttf') format('truetype');
    font-weight: @weight;
    font-style: @style;
  }
}
@text-selection-bg:#C55A48;
html{
  overflow-x: hidden;
  font-size: 16px;
  width: 100%;
  // overflow-y: hidden

}
// #root{
//   height:100vh;
//   overflow-y: auto;
// }

#canvas-container { 
  // transform: rotate(25deg);
  display: none;
  width: 100%;
  background-color: #292929;
  // position: fixed;
  top: 0;
  z-index: 99;
  overflow: hidden;
   canvas{
    margin-left: 100px
   }
 }


.roboto-font-one(@type, @weight, @style) {
  .roboto-font('@{type}', @weight, @style);
}

.roboto-font-pair(@type, @weight) {
  .roboto-font('@{type}', @weight, normal);
  .roboto-font('@{type}Italic', @weight, italic);
}

.roboto-font-one('Italic', 200, italic);
.roboto-font-one('Regular', 400, normal);
.roboto-font-pair('Light', 300);
.roboto-font-pair('Medium', 500);
.roboto-font-pair('Bold', 700);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Optima',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@primary-color: #A01E28;