

@banner3: banner3;
.@{banner3} {
  // 如果在第一屏且导航位置为 relative, 一屏为 height: calc(~"100vh - 64px");
  width: 100%;
  height: calc(100vh - 72px);
  position: relative;
  text-align: center;
  border-color: #666;
  // background-image: url("https://gw.alipayobjects.com/zos/rmsportal/xTPkCNNLOnTEbGgVZOpE.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  
  display: flex;
  z-index: 2;
  & &-text-wrapper {
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    color: #C55A48;
    // max-width: calc(61.375rem / @rate);
    height: 500px;
    width: 100%;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    display: flex;
    align-items: center;
    >.queue-anim-leaving {
      position: relative !important;
    }
  }
  & &-slogan {
    margin: 26px auto 38px;
    overflow: hidden;
    color: #C55A48;
    text-align: center;
    font-family: Optima;
    font-size:  3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  & &-name-en {
    display: block;
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
  }
  & &-name {
    font-size: 24px;
    overflow: hidden;
    opacity: 0.8;
  }
  & &-button {
    display: block;
    margin: 72px auto 0;
    background: rgb(3, 67, 101);
    background: -moz-linear-gradient(left, rgba(3, 67, 101, 1) 0%, rgba(0, 27, 51, 1) 100%);
    background: linear-gradient(to right, rgba(3, 67, 101, 1) 0%, rgba(0, 27, 51, 1) 100%);
    box-shadow: 0 8px 16px #0a52ab;
    border: none;
    transition: background .45s @ease-out;
    width: 132px;
    line-height: 42px;
    height: 42px;
    border-radius: 42px;
  }
  & &-time {
    font-size: 14px;
    line-height: 24px;
    margin-top: 24px;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &-fadein{
    opacity: 1;
    animation: fadeIn 0.5s ease-in-out; 
  }

  &-fadeout{
    opacity: 0;
    animation: fadeOut 0.5s ease-in-out; 
  }

   &-block{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    width: calc(49.6875rem / @rate);
    margin: auto;
    position: relative;
    // height: calc(15.875rem / @rate);
   }
   &-mask{
    background-size: 100% 100%;
    background-image: url("../../../public/home/banner-mask-logo.png");
    width: calc(46.875rem / @rate);
    height: calc(15.625rem / @rate);
    cursor: pointer;
   }

   &-item{
    background-size: 100% 100%;
    background-image: url("../../../public/home/banner-logo.png");
    width: calc(46.875rem / @rate);
    height: calc(15.625rem / @rate);
    cursor: pointer;
   }

  //  &-item {
  //   position: relative;
  //   &-img{
  //     background-size: 100% 100%;
  //   }
  //   &-e{
  //     background-image: url("../../../public/home/logos/e.png");
  //     width: calc(7.01425rem / @rate);
  //     background-size: 100% 100%;
  //     height: calc(11.827rem / @rate);
  //   }
  //   .e1{
  //     background-image: url("../../../public/home/logos/e1.png") !important;
  //     margin-top: calc(1.09rem / @rate);
  //   }

  //   &-t{
  //     background-image: url("../../../public/home/logos/t.png");
  //     width: calc(8.51088rem / @rate);
  //     background-size: 100% 100%;
  //     height: calc(11.82694rem / @rate);
  //   }
  //   .t1{
  //     background-image: url("../../../public/home/logos/t1.png") !important;
  //     margin-top: calc(1.09rem / @rate);
  //   }

  //   &-n{
  //     background-image: url("../../../public/home/logos/n.png");
  //     width: calc(8.54569rem / @rate);
  //     background-size: 100% 100%;
  //     height: calc(11.84481rem / @rate);
  //   }
  //   .n1{
  //     background-image: url("../../../public/home/logos/n1.png") !important;
  //     margin-top: calc(1.09rem / @rate);
  //   }

  //   &-a{
  //     background-image: url("../../../public/home/logos/a.png");
  //     width: calc(15.23519rem / @rate);
  //     background-size: 100% 100%;
  //     height: calc(15.87556rem / @rate);
  //   }
  //   .a1{
  //     background-image: url("../../../public/home/logos/a1.png") !important;
  //     margin-top: calc(1.09rem / @rate);
  //   }
  //  }

}

@media screen and (max-width: 767px) {
  .@{banner3} {
    background-attachment: inherit;
    & &-text-wrapper {
      width: 90%;
      height: 50%;
    }
    & &-name-en {
      font-size: 12px;
    }
    & &-slogan {
      font-size: 15px;
      line-height: 1.5;
      margin: 12px 0;
    }
    & &-name {
      font-size: 14px;
    }

    &-item{
      background-size: 100% 100%;
      background-image: url("../../../public/home/banner-logo.png");
      width: calc(100vw - 60px);
      height: calc((100vw - 60px) / 3);
      cursor: pointer;
     }
     &-mask{
      background-size: 100% 100%;
      background-image: url("../../../public/home/banner-mask-logo.png");
      width: calc(100vw - 60px);
      height: calc((100vw - 60px) / 3);
      cursor: pointer;
     }
     .mast-point{
      width: 8px;
      height: 18px;
    }
    
  
  }
}
