body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.templates-wrapper {
  position: relative;
}
.keyword-wrapper {
  max-width: 100% !important;
  height: calc(100vh - 64px) !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.keyword-item {
  color: #c55a48;
  text-align: center;
  font-family: Optima;
  font-size: calc(4.5rem / 1.406);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.keyword-line {
  display: flex;
  justify-content: space-around;
  height: calc(0.625rem / 1.406);
  width: 100%;
  background: linear-gradient(270deg, rgba(197, 90, 72, 0) 0%, rgba(197, 90, 72, 0.7) 13.21%, #c55a48 52.6%, rgba(197, 90, 72, 0.7) 88.04%, rgba(197, 90, 72, 0) 100%);
}
.keyword-block {
  width: calc(100vw / 1.406);
  display: flex;
  justify-content: center;
  position: relative;
}
.keyword-block-img {
  width: calc(120px / 1.406);
  height: calc(120px / 1.406);
  background-image: url('../../../public/home/circle.png');
  background-size: 100% 100%;
  margin-top: calc(((60px / 1.406) * -1) + (0.625rem / 1.406 / 2));
}
.keyword-content0-block {
  min-height: auto !important;
}
.keyword-item-desc {
  max-width: calc(26.9375rem / 1.406);
  color: #c55a48;
  text-align: justify;
  font-family: Optima;
  font-size: calc(1.5rem / 1.406);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 40px auto;
}
.research-title {
  color: #fff;
  text-align: center;
  font-family: Optima;
  font-size: calc(6.25rem / 1.406);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: calc(4.4rem / 1.406);
  text-align: left;
}
.research-item-icon {
  width: calc(3.75rem / 1.406);
  height: calc(3.75rem / 1.406);
  background-image: url('../../../public/home/file.png');
  background-size: cover;
  background-position: center;
  margin-right: 16px;
  flex-shrink: 0;
  margin-top: 5px;
}
.research-item {
  margin-bottom: 34px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}
.research-item-title-it {
  border-bottom: 2px solid #ffffff00;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  padding: 0 10px 0 0;
}
.research-item:hover .research-item-title-it {
  border-color: #fff;
}
.research-item:hover .research-item-title {
  font-weight: 700;
}
.research-item-title {
  color: #fff;
  font-family: Optima;
  font-size: calc(2.25rem / 1.406);
  font-style: italic;
  font-weight: 400;
  /* 333.333% */
  height: calc(7.5rem / 1.406);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.research-wrapper {
  background-image: none !important;
  overflow: hidden !important;
  min-height: 100vh;
  background-color: #c55a48;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.research-wrapper-img {
  position: absolute;
  width: 100%;
  height: calc(30.41819rem / 1.406);
  top: 50%;
  margin-top: calc((30.41819rem / 1.406 / 2) * -1);
  background-position: center;
  background-size: auto calc(30.41819rem / 1.406);
  background-size: 100% 100%;
  background-image: url('../../../public/home/bg2.png');
}
.research-img {
  width: calc(66.0625rem / 1.406);
  height: calc(47.1875rem / 1.406);
  background-position: center;
  background-size: auto calc(30.41819rem / 1.406);
  background-size: 100% 100%;
  background-image: url('../../../public/home/space.png');
}
.research-item-btn {
  width: calc(15.875rem / 1.406);
  height: calc(4.1875rem / 1.406);
  border-radius: 0.3125rem;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  text-align: center;
  font-family: Optima;
  font-size: calc(2rem / 1.406);
  font-style: normal;
  font-weight: 700;
  line-height: calc(4.1875rem / 1.406);
  margin-top: calc(3.75rem / 1.406);
  cursor: pointer;
}
.project-block-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(197, 90, 72, 0.3);
  z-index: 10;
}
.project-block {
  display: flex;
  width: 100%;
}
.project-item {
  flex: 1;
  height: calc(100vh - 64px);
  margin-left: -20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bytedance {
  background-size: 100% 100%;
  background-image: url('../../../public/home/bytedance_bg.png');
  z-index: 9;
}
.bytedance-logo {
  width: calc(29.8125rem / 1.406);
  height: calc(9rem / 1.406);
  background-size: 100% 100%;
  background-image: url('../../../public/home/bytedance_logo.png');
  z-index: 11;
}
.spacex {
  background-size: 100% 100%;
  background-image: url('../../../public/home/spacex_bg.png');
  z-index: 8;
}
.spacex-logo {
  width: calc(29.625rem / 1.406);
  z-index: 11;
  height: calc(7.8125rem / 1.406);
  background-size: 100% 100%;
  background-image: url('../../../public/home/spacex_logo.png');
}
.epic_games {
  background-size: 100% 100%;
  background-image: url('../../../public/home/epic_bg.png');
  z-index: 7;
}
.epic_games-logo {
  width: calc(15rem / 1.406);
  z-index: 11;
  height: calc(15.625rem / 1.406);
  background-size: 100% 100%;
  background-image: url('../../../public/home/epic_logo.png');
}
.discord {
  background-size: 100% 100%;
  background-image: url('../../../public/home/disco_bg.png');
  z-index: 6;
}
.discord-logo {
  width: calc(33.75rem / 1.406);
  z-index: 11;
  height: calc(11.75rem / 1.406);
  background-size: 100% 100%;
  background-image: url('../../../public/home/disco_logo.png');
}
.project-block-notice {
  width: 100vw;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: 0;
  z-index: 12;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(15rem / 1.406);
}
.project-block-notice-title {
  color: #c55a48;
  text-align: center;
  font-family: Optima;
  font-size: calc(3.75rem / 1.406);
  font-style: normal;
  font-weight: 700;
  line-height: calc(3.75rem / 1.406);
  /* 100% */
}
.project-block-notice-desc {
  color: #c55a48;
  font-family: Optima;
  font-size: calc(2.25rem / 1.406);
  font-style: normal;
  font-weight: 700;
  line-height: calc(3.75rem / 1.406);
}
.project-block-notice-tip {
  color: rgba(96, 96, 96, 0.75);
  text-align: right;
  font-family: Optima;
  font-size: calc(0.75rem / 1.406);
  font-style: normal;
  font-weight: 400;
  line-height: calc(1.875rem / 1.406);
  /* 250% */
  position: absolute;
  bottom: calc(0.44rem / 1.406);
  right: calc(1rem);
}
.connect {
  background: #c55a48;
  height: calc(100vh - 72px) !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.connect-block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - (20rem / 1.406));
  position: absolute;
  top: 0;
}
.connect-block ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #fff !important;
  text-align: center;
  font-family: Optima;
  font-size: calc(1.875rem / 1.406);
  font-weight: 400;
}
.connect-block :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  text-align: center;
  font-family: Optima;
  font-size: calc(1.875rem / 1.406);
  font-weight: 400;
}
.connect-block ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
  text-align: center;
  font-family: Optima;
  font-size: calc(1.875rem / 1.406);
  font-weight: 400;
}
.connect-block :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
  text-align: center;
  font-family: Optima;
  font-size: calc(1.875rem / 1.406);
  font-weight: 400;
}
.connect-title {
  color: #fff;
  text-align: center;
  font-family: Optima;
  font-size: calc(6.25rem / 1.406);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.connect-desc {
  color: #fff;
  text-align: center;
  font-family: Optima;
  font-size: calc(1.875rem / 1.406);
  font-style: normal;
  font-weight: 400;
  line-height: calc(7.5rem / 1.406);
  /* 400% */
}
.connect-input {
  width: calc(57.5rem / 1.406) !important;
  height: calc(6.25rem / 1.406) !important;
  border-radius: 0.3125rem;
  background: rgba(255, 255, 255, 0.3);
  width: calc(11.25rem / 1.406);
  height: calc(4.1875rem / 1.406);
  text-align: center;
  border: 0;
  color: #fff;
  font-family: Optima;
  font-size: calc(1.875rem / 1.406);
  font-weight: 400;
}
.connect-tip {
  position: absolute;
  height: 50px;
  width: 220px;
  background-image: url('../../../public/home/tip.png');
  background-size: 100% 100%;
  top: 50%;
  right: 10px;
  margin-top: -25px;
  color: #c55a48;
  font-family: Optima;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  line-height: 50px;
  padding-left: 10px;
}
.connect-btn {
  width: 300px;
  height: 66px;
  border-radius: 0.3125rem;
  background: #fff;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  color: #c55a48;
  justify-content: center;
  text-align: center;
  font-family: Optima;
  font-size: calc(2.5rem / 1.406);
  font-style: normal;
  font-weight: 700;
  line-height: 66px;
  margin-top: calc(6.31rem / 1.406);
  cursor: pointer;
}
.connect-footer-logo {
  width: calc(6.85881rem / 1.406);
  height: calc(2.32475rem / 1.406);
  flex-shrink: 0;
  background-image: url('../../../public/home/fotterlogo.png');
  background-size: 100% 100%;
}
.connect-footer-in {
  width: calc(3.0625rem / 1.406);
  height: calc(3.0625rem / 1.406);
  flex-shrink: 0;
  background-image: url('../../../public/home/in.png');
  background-size: 100% 100%;
}
.connect-footer-x {
  width: calc(1.75rem / 1.406);
  height: calc(1.5625rem / 1.406);
  margin: 0 5px 0 10px;
  flex-shrink: 0;
  background-image: url('../../../public/home/x.png');
  background-size: 100% 100%;
}
.logo-div {
  line-height: calc(5.875rem / 1.406);
  /* 300% */
  height: calc(5.875rem / 1.406);
  /* 300% */
  position: absolute;
  top: calc(3.2rem / 1.406);
  left: calc(5rem / 1.406);
}
.logo-img {
  width: calc(18.87275rem / 1.406);
  height: calc(3.09956rem / 1.406);
}
.link {
  color: #fff;
  font-family: Optima;
  font-size: calc(2.5rem / 1.406);
  font-style: normal;
  font-weight: 400;
  line-height: calc(5.875rem / 1.406);
  /* 300% */
  height: calc(5.875rem / 1.406);
  white-space: nowrap;
}
.connect-footer {
  background: #7e3a2e;
  height: calc(15rem / 1.406);
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 calc(5rem / 1.406);
  align-items: center;
}
.connect-footer-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #fff;
  font-family: Optima;
  font-size: calc(1.5rem / 1.406);
  font-style: normal;
  font-weight: 400;
  margin-top: 16px;
}
.sha {
  animation: blink 1s step-start infinite;
}
.mast-point {
  width: 1.25rem;
  height: 2.25rem;
  margin-left: 5px;
  flex-shrink: 0;
  background: #c55a48;
  display: inline-block;
  opacity: 0;
  /* 初始状态为完全透明 */
  animation: fadeIn 1s ease-in-out 3s forwards, blink 1s step-end infinite;
  /* 使用fadeIn动画，2秒后开始，持续1秒，使用ease-in-out过渡效果，然后应用闪动动画 */
  animation-delay: 3s;
}
.chat3 {
  background: #c55a48;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    /* 初始状态为完全透明 */
  }
  100% {
    opacity: 1;
    /* 最终状态为完全可见 */
  }
}
/* 定义一个光标闪动的关键帧 */
@keyframes blink {
  0% {
    opacity: 1;
    /* 初始状态为完全可见 */
  }
  50% {
    opacity: 0;
    /* 中间状态为完全透明 */
  }
  100% {
    opacity: 1;
    /* 最终状态为完全可见 */
  }
}
.point {
  color: #c55a48;
  text-align: center;
  font-family: Optima;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media screen and (max-width: 1500px) {
  .banner-chat {
    padding: 20px !important;
  }
  .connect-title {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 1200px) {
  .banner-chat {
    padding: 20px !important;
  }
  .connect-title {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 767px) {
  .banner-chat {
    padding: 20px !important;
  }
  .menu-btn {
    width: calc(11.25rem / 1.5 / 1.406);
    height: calc(4.1875rem / 1.5 / 1.406);
    font-size: calc(1.75rem / 1.5 / 1.406);
    line-height: calc(4.1875rem / 1.5 / 1.406);
  }
  .research-img {
    width: calc(100vw - 60px);
    height: calc((100vw - 60px) / 1.4);
  }
  .research-title {
    font-size: 24px;
  }
  .research-item {
    align-items: flex-start;
    margin-bottom: 48px;
  }
  .research-item-icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
  .research-item-title {
    height: auto;
    font-size: 20px;
    line-height: 24px;
  }
  .research-item-btn {
    width: 100%;
  }
  .project-block {
    flex-direction: column;
  }
  .project-item {
    flex: none;
    width: 100%;
    margin: 0;
    height: 160px;
    background-size: auto;
    background-position: center;
  }
  .connect-title {
    font-size: 32px;
  }
  .connect-desc {
    font-size: 14px;
    line-height: 24px;
    margin: 10px 0 20px;
  }
  .connect-input {
    width: 300px !important;
    height: 40px !important;
    font-size: 16px;
  }
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    font-size: 16px;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-size: 16px;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-size: 16px;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 16px;
  }
  .connect-btn {
    width: 200px !important;
    font-size: 16px !important;
    height: 40px !important;
  }
  .connect-footer-desc {
    font-size: 12px;
    margin: 0;
  }
  .connect-footer {
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
  }
  .connect-footer-logo {
    margin-bottom: 10px;
  }
  .epic_games-logo {
    z-index: 11;
    width: 70px;
    height: calc(70px / 0.96);
  }
  .discord-logo {
    width: 200px;
    height: calc(200px / 2.87);
  }
  .spacex-logo {
    width: 200px;
    height: calc(200px / 3.972);
  }
  .bytedance-logo {
    width: 200px;
    height: calc(200px / 3.3125);
  }
  .project-block-notice-title {
    font-size: 24px;
  }
  .project-block-notice-desc {
    font-size: 14px;
  }
  .project-block-notice-tip {
    position: relative;
    text-align: center;
    margin: 0 10px;
    right: auto;
  }
  .project-block-notice {
    height: calc(18rem / 1.406);
  }
}
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header3 {
  background: none;
  width: 100%;
  z-index: 1;
  position: sticky !important;
  z-index: 9999;
  top: -1px;
  background-color: #fff !important;
  border: 1px solid #EBEBEB;
}
.header3 .nav3 {
  background-color: #fff !important;
  height: 92px;
}
.header3 .home-page {
  padding: 0;
  max-width: 1340px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px !important;
  padding: 0 24px 0 25px;
}
.header3-logo {
  display: inline-block;
  position: relative;
  width: 179px;
  height: 39px;
}
.header3-logo-img {
  background-size: 100% 100%;
  width: 179px;
  height: 39px;
}
.header3-logo a {
  display: block;
}
.header3-menu {
  float: right;
}
.header3-menu .ant-menu-horizontal {
  border-bottom: none;
}
.header3-menu .ant-menu {
  line-height: 62px;
  height: 64px;
  background: transparent;
}
.header3-menu .ant-menu a {
  display: block;
}
.header3-item-block {
  padding: 0 8px;
}
.header3-item-block > * {
  display: inline-block;
}
.header3-item .ant-menu-sub .ant-menu-item,
.header3-item-child .ant-menu-sub .ant-menu-item,
.header3-menu .ant-menu-sub .ant-menu-item,
.header3-item .ant-menu-inline .ant-menu-item,
.header3-item-child .ant-menu-inline .ant-menu-item,
.header3-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header3-item .item-sub-item,
.header3-item-child .item-sub-item,
.header3-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header3-item .item-image,
.header3-item-child .item-image,
.header3-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header3-item .item-title,
.header3-item-child .item-title,
.header3-menu .item-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 46px;
}
.header3-item .item-content,
.header3-item-child .item-content,
.header3-menu .item-content {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  margin-left: 46px;
}
@media screen and (max-width: 767px) {
  .header3-logo {
    z-index: 101;
  }
  .header3.home-page-wrapper .home-page {
    padding: 12px 24px;
  }
  .header3-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: #fff;
  }
  .header3-menu li {
    padding: 0 24px;
  }
  .header3-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header3-menu .item-sub-item {
    padding: 8px 0;
  }
  .header3-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header3-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #001529;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header3-mobile-menu :first-child {
    margin-top: 0;
  }
  .header3 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header3 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header3 .open {
    height: auto;
  }
  .header3 .open .header3-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header3 .open .header3-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header3 .open .header3-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header3 .open > .header3-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header3-item-block {
    height: 40px;
    line-height: 40px;
  }
}
.banner3 {
  width: 100%;
  height: calc(100vh - 72px);
  position: relative;
  text-align: center;
  border-color: #666;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  display: flex;
  z-index: 2;
}
.banner3 .banner3-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  color: #C55A48;
  height: 500px;
  width: 100%;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.banner3 .banner3-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner3 .banner3-slogan {
  margin: 26px auto 38px;
  overflow: hidden;
  color: #C55A48;
  text-align: center;
  font-family: Optima;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.banner3 .banner3-name-en {
  display: block;
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
}
.banner3 .banner3-name {
  font-size: 24px;
  overflow: hidden;
  opacity: 0.8;
}
.banner3 .banner3-button {
  display: block;
  margin: 72px auto 0;
  background: #034365;
  background: -moz-linear-gradient(left, #034365 0%, #001b33 100%);
  background: linear-gradient(to right, #034365 0%, #001b33 100%);
  box-shadow: 0 8px 16px #0a52ab;
  border: none;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 132px;
  line-height: 42px;
  height: 42px;
  border-radius: 42px;
}
.banner3 .banner3-time {
  font-size: 14px;
  line-height: 24px;
  margin-top: 24px;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.banner3-fadein {
  opacity: 1;
  animation: fadeIn 0.5s ease-in-out;
}
.banner3-fadeout {
  opacity: 0;
  animation: fadeOut 0.5s ease-in-out;
}
.banner3-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(49.6875rem / 1.406);
  margin: auto;
  position: relative;
}
.banner3-mask {
  background-size: 100% 100%;
  background-image: url("../../../public/home/banner-mask-logo.png");
  width: calc(46.875rem / 1.406);
  height: calc(15.625rem / 1.406);
  cursor: pointer;
}
.banner3-item {
  background-size: 100% 100%;
  background-image: url("../../../public/home/banner-logo.png");
  width: calc(46.875rem / 1.406);
  height: calc(15.625rem / 1.406);
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .banner3 {
    background-attachment: inherit;
  }
  .banner3 .banner3-text-wrapper {
    width: 90%;
    height: 50%;
  }
  .banner3 .banner3-name-en {
    font-size: 12px;
  }
  .banner3 .banner3-slogan {
    font-size: 15px;
    line-height: 1.5;
    margin: 12px 0;
  }
  .banner3 .banner3-name {
    font-size: 14px;
  }
  .banner3-item {
    background-size: 100% 100%;
    background-image: url("../../../public/home/banner-logo.png");
    width: calc(100vw - 60px);
    height: calc((100vw - 60px) / 3);
    cursor: pointer;
  }
  .banner3-mask {
    background-size: 100% 100%;
    background-image: url("../../../public/home/banner-mask-logo.png");
    width: calc(100vw - 60px);
    height: calc((100vw - 60px) / 3);
    cursor: pointer;
  }
  .banner3 .mast-point {
    width: 8px;
    height: 18px;
  }
}
.conte5 {
  overflow: hidden !important;
}
.content6-wrapper {
  min-height: 100vh;
  background-color: #c55a48;
  overflow: inherit !important;
}
.content6-wrapper .bg {
  width: 100%;
  height: 100vh;
  top: 0;
  background-image: url('../../../public/home/bg1.png');
  background-size: cover;
  background-position: center;
  z-index: 10;
}
.content6-wrapper .point {
  background-image: url('../../../public/home/point.png');
  background-size: 100% 100%;
  width: calc(7.5rem / 1.406);
  height: calc(7.5rem / 1.406);
}
.content6-wrapper .point-block {
  position: absolute;
  left: calc(7.5rem / 1.406 * -1);
  top: -20px;
}
.content6-wrapper .point-line {
  height: 10px;
  background-color: #fff;
  width: 100px;
  position: absolute;
  right: calc(7.5rem / 1.406 / 2);
  top: 50%;
  margin-top: -5px;
}
.content6-wrapper .list-block-mask {
  position: sticky;
  width: 100%;
  height: 300px;
  background: linear-gradient(to bottom, #c55a48 30%, rgba(145, 152, 229, 0));
  margin-top: -100px;
  z-index: 9;
  top: 0;
}
.content6-wrapper .list-block {
  height: 100%;
  max-width: calc(30rem / 1.406);
  position: relative;
}
.content6-wrapper .list-block .list-title {
  font-family: Optima;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  text-align: center;
  line-height: calc(6.25rem / 1.406);
}
.content6-wrapper .list-block .list-desc {
  color: #fff;
  text-align: center;
  font-family: Optima;
  font-style: normal;
  font-weight: 400;
  margin-bottom: calc(5.44rem / 1.406);
  height: calc(6.25rem / 1.406);
  line-height: normal;
}
.content6-wrapper .list-block .small {
  opacity: 0.7;
}
.content6-wrapper .list-block .small .list-title {
  font-size: calc(5rem / 1.406);
}
.content6-wrapper .list-block .small .list-desc {
  font-size: calc(1.5rem / 1.406);
}
.content6-wrapper .list-block .mid {
  opacity: 1;
}
.content6-wrapper .list-block .mid .list-title {
  font-size: calc(7.25rem / 1.406);
}
.content6-wrapper .list-block .mid .list-desc {
  font-size: calc(2rem / 1.406);
}
.content6-wrapper .list-block .large {
  opacity: 1;
}
.content6-wrapper .list-block .large .list-title {
  font-family: Optima;
  font-size: calc(8rem / 1.406);
  font-weight: 400;
}
.content6-wrapper .list-block .large .list-desc {
  font-size: calc(1.5rem / 1.406);
  font-weight: 400;
}
.content6-wrapper .content6-child-text {
  color: #fff;
  text-align: justify;
  font-family: Optima;
  font-size: calc(3.75rem / 1.406);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: calc(16.62rem / 1.406);
}
.content6-wrapper .content6 {
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.content6-wrapper .content6-title,
.content6-wrapper .content6-content {
  color: #fff;
  text-align: justify;
  font-family: Optima;
  font-size: calc(3rem / 1.406);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.content6-wrapper .content6-text {
  min-height: 424px;
  position: sticky !important;
  top: 0;
}
.content6-wrapper .content6-text > *.queue-anim-leaving {
  position: relative !important;
}
.content6-wrapper .content6-text .title-h1 {
  position: relative;
  margin: 0 0 16px;
  text-align: left;
  font-size: 2em;
}
.content6-wrapper .content6-text .title-content {
  position: relative;
  margin-bottom: 64px;
  text-align: left;
}
.content6-wrapper .content6-text ul {
  position: relative;
  display: inline-block;
}
.content6-wrapper .content6-text ul li {
  margin-bottom: 24px;
}
.content6-wrapper .content6-text ul li .content6-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
}
.content6-wrapper .content6-text ul li .content6-title,
.content6-wrapper .content6-text ul li .content6-content {
  margin-left: 45px;
}
.content6-wrapper .content6-text ul li .content6-title {
  font-size: 14px;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .content6-wrapper {
    height: 860px;
    overflow: hidden;
  }
  .content6-wrapper .content6-text {
    position: relative !important;
  }
  .content6-wrapper .content6 {
    display: block;
  }
  .content6-wrapper .content6-img,
  .content6-wrapper .content6-text {
    display: block;
    width: 100%;
  }
  .content6-wrapper .content6-text > h1,
  .content6-wrapper .content6-text > p {
    text-align: center;
  }
  .content6-wrapper .content6-text > h1 {
    margin: 56px auto 16px;
  }
  .content6-wrapper .content6-text p {
    margin-bottom: 32px;
  }
  .content6-wrapper .content6-img {
    margin-top: 20px;
  }
  .content6-wrapper .content6-text {
    min-height: auto;
  }
  .content6-wrapper .content6-child-text {
    margin-top: 24px;
    font-size: 24px;
  }
  .content6-wrapper .list-block-mask {
    display: none;
  }
  .content6-wrapper .list-title {
    font-size: calc(4rem / 1.406) !important;
  }
  .content6-wrapper .list-desc {
    margin-bottom: 0 !important;
  }
}
.content11-wrapper {
  height: 100vh;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.content11-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content11-wrapper .button {
  box-shadow: 0 8px 16px #0554b7;
  background: linear-gradient(to right, #05cbff, #1e5aff) !important;
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  border: 0;
  border-radius: 21px;
  color: #fff;
  width: 128px;
  padding: 0 15px;
  display: inline-block;
  transition: transform 0.3s, box-shadow 0.3s;
}
.content11-wrapper .button:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 20px #0554b7;
}
.content11-wrapper .button:active {
  transform: translateY(4px);
  box-shadow: 0 4px 8px #0554b7;
}
.content11-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .content11-wrapper {
    padding-bottom: 0;
  }
}
.content0-wrapper {
  height: calc(100vh - 72px);
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0-block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 100%;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  width: 100px;
  height: 100px;
  margin: auto;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 10px auto;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    min-height: 880px;
  }
  .content0-wrapper .keyword-item {
    font-size: 32px !important;
  }
  .content0-wrapper .keyword-item-desc {
    margin: 24px auto;
  }
  .content0-wrapper .keyword-content0-block {
    width: 100%;
  }
}
.teams0-wrapper {
  height: 430px;
}
.teams0-wrapper .teams0 {
  padding: 64px 24px;
  display: flex;
  align-items: flex-end;
}
.teams0-wrapper .teams0 .banner-anim {
  width: 100%;
  height: 100%;
}
.teams0-wrapper .teams0 .banner-anim-thumb span {
  background: #e9e9e9;
  box-shadow: none;
}
.teams0-wrapper .teams0 .banner-anim-thumb span.active {
  background: #A01E28;
}
.teams0-wrapper .teams0 .queue-anim-leaving {
  position: relative !important;
}
.teams0-wrapper .teams0-banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.teams0-wrapper .teams0-image {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}
.teams0-wrapper .teams0-image img {
  height: 100%;
}
.teams0-wrapper .teams0-content {
  font-size: 12px;
  color: #919191;
  text-align: center;
  width: 80%;
  margin: 8px auto;
}
.teams0-wrapper .teams0-h1 {
  font-size: 24px;
  text-align: center;
  width: 80%;
  margin: 24px auto 0;
}
@media screen and (max-width: 767px) {
  .teams0-wrapper {
    min-height: 480px;
  }
  .teams0-wrapper .teams0 {
    display: block;
  }
}
input:focus {
  border: 0;
  /* 修改边框样式 */
  outline: none;
  /* 移除默认的轮廓样式 */
}
.footer1-wrapper {
  background: #7E3A2E;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 180px;
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #A01E28;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 80px;
  text-align: center;
  line-height: 80px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
#Content11_0.lmefhvwvkro-editor_css {
  background-image: ;
  background-attachment: ;
  background-blend-mode: ;
  background-position: ;
  background-repeat: ;
  background-size: ;
  background-clip: ;
}
