#Feature3_0 .home-page > .title-wrapper > .l3jvhfbfzzd-editor_css {
  font-size: 48px;
  line-height: 125%;
  color: #000;
  margin: 0px 0 32px;
}
#Feature3_0 .ant-col > .content3-text > .l3jvj217fbt-editor_css {
  font-size: 20px;
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 137.69%;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 14px;
}
#Feature3_0 .ant-col > .content3-text > .l3jvji77ws-editor_css {
  font-size: 13px;
  line-height: 160%;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 160%;
  // text-transform: capitalize;
  color: #000000;
}
.home-page > .title-wrapper > .l3jvi2ssiz-editor_css {
  display: inline-block;
  font-size: 20px;
  line-height: 150%;
  color: rgb(0, 0, 0);
  max-width: 608px;
  position: relative;

  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #000000;
}
.l3jvi2ssiz {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}
.w-e-text-container [data-slate-editor] blockquote {
  margin: 0 !important;
  border-left: 4px solid #676599 !important;
  padding: 5px 10px 5px 16px !important;
}
.w-e-text-container [data-slate-editor] p{
  margin: 0 !important;
}
.w-e-text-container [data-slate-editor] h1, .w-e-text-container [data-slate-editor] h2, .w-e-text-container [data-slate-editor] h3, .w-e-text-container [data-slate-editor] h4, .w-e-text-container [data-slate-editor] h5{
  margin: 0 !important;
}
.e-yiyan {
  padding: 5px 10px;
  background-color: #676599;
}
a[data-slate-inline] {
  color: #088fc9;
  text-decoration: none;
  font-weight: bold;
}
video{
  width: 100%;
}
.w-e-textarea-video-container{
  background-image: none !important;
  border: 0 !important;
}
@media screen and (max-width: 767px) {
  #Feature3_0 .home-page > .title-wrapper > .l3jvhfbfzzd-editor_css {
    font-size: 24px;
    line-height: 125%;
    margin-bottom: 12px;
  }
  .home-page > .title-wrapper > .l3jvi2ssiz-editor_css {
    font-size: 12px;
    line-height: 150%;
  }
  #Feature3_0 .ant-col > .content3-text > .l3jvj217fbt-editor_css {
    font-size: 15px;
    line-height: 137.69%;
    margin-bottom: 6px;
  }
  #Feature3_0 .ant-col > .content3-text > .l3jvji77ws-editor_css {
    font-size: 9px;
    line-height: 140%;
    // word-break: break-all
  }
  .l3jvi2ssiz {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 16px !important;
  }
}

