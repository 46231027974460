@banner1: banner1;
.@{banner1} {
  // 如果在第一屏且导航位置为 relative, 一屏为 height: calc(~"100vh - 64px");
  width: 100%;
  height: 100vh;
  position: relative;
  border-color: #666;
  background: #fff;
  &-wrapper,
  .banner-anim {
    height: 100%;
  }
  & .queue-anim-leaving {
    position: relative !important;
  }
  .banner-user-elem {
    height: 100%;
    color: #fff;
    position: relative;
    overflow: hidden;
  }
  .bg0 {
    background: #eff0fc;
  }
  .bg1 {
    background: #eff7fc;
  }
  .bg2 {
    background: #f6effc;
  }
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-size: cover;
  }
  .banner1-title-text {
    font-family: 'DM Serif Display';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 125%;
    text-align: right;
    color: #000000;
    margin-bottom: 17px;
    span {
      position: relative;
      z-index: 9;
    }
  }

  .banner-user-elem .banner-user-title {
    font-size: 22px;
    top: 40%;
  }
  .banner-user-elem .banner-user-text {
    top: 40%;
  }
  .banner1-text-icon {
    position: absolute;
    right: 37px;
    width: 313px;
    top: 21px;
    height: 46px;
  }
  & &-text-wrapper {
    display: block;
    position: relative;
    top: 70px;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 14px;
    color: @template-text-color-light;
    max-width: 1200px;
    width: 100%;
    height: 500px;
    text-align: right;
  }
  & &-title {
    // width: 350px;
    // left: 30px;
    // margin: auto;
    display: inline-block;
    // font-size: 40px;
    text-align: right;
    position: relative;
  }
  & &-content {
    margin-bottom: 54px;
    word-wrap: break-word;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 150%;
    text-align: right;
    color: #000000;
  }
  & &-button {
    border: 1px solid #fff;
    color: #fff;
    background: transparent;
    box-shadow: 0 0 0 transparent;
    transition: background 0.45s @ease-out, box-shadow 0.45s @ease-out;
    line-height: 36px;
    font-size: 16px;
    height: 36px;
    & span {
      text-shadow: 0 0 0 rgba(0, 0, 0, 0);
      transition: text-shadow 0.45s @ease-out;
    }
    &:hover {
      color: #fff;
      border-color: #fff;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
      & span {
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
      }
    }

    &.queue-anim-leaving {
      width: auto;
    }
  }
  & &-icon {
    bottom: 20px;
    font-size: 24px;
    position: absolute;
    z-index: 10;
    left: 50%;
    margin-left: -12px;
    color: @template-text-color-light;
  }
  &-block {
    min-height: 512px;
  }
  &-block-div {
    width: 100%;
    min-height: 512px;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content:space-around;
    padding: 0 34px;
  }

  .div1 {
    background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg9.png');
    background-size: 100% 100%;
  }

  .div2 {
    background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg10.png');
    background-size: 100% 100%;
  }

  .div3 {
    background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg11.png');
    background-size: 100% 100%;
  }
  &-block-title {
    font-family: 'DM Serif Display';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 125%;
    text-align: right;
    color: #000000;
    max-width: 585px;
  }
  &-block-img {
    width: 352px;
    height: 352px;
    object-fit: cover;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.banner-anim-thumb-default span {
  opacity: 0.2;
  width: 24px;
  height: 4px;
  background: #a01e28;
  border-radius: 1px;
}
.banner-anim-thumb-default .active {
  opacity: 1;
  background: #a01e28 !important;
}
.banner-anim-thumb {
  bottom: 15%;
}


@media screen and (max-height: 1000px) {
  .banner-anim-thumb {
    bottom: 15%;
  }
}
@media screen and (max-height: 900px) {
  #bgimg1{
    display:none !important;
  }
  .banner-anim-thumb {
    bottom: 0%;
  }
}

@media screen and (max-height: 800px)  {
  .banner-anim-thumb {
    bottom: 0%;
  }
}
@media screen and (max-width: 767px) {
  #bgimg1{
    display:block !important;
  }
  .@{banner1} {
    height: 675px;
    & &-text-wrapper {
      width: 90%;
      .@{banner1}-title {
        width: 90%;
        left: 0;
      }
    }
    .bg {
      background-attachment: inherit;
    }
    .banner1-title-text {
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 12px;
     
    }
    .banner1-text-icon {
      position: absolute;
      right: 44px;
      width: 138px;
      top: 8px;
      height: 20px;
      left: auto
    }
    & &-content {
      margin-bottom: 36px;
      font-size: 12px;
      line-height: 12px;
    }
    &-block-title {
      font-size: 15px;
      line-height: 125%;
      margin-bottom: 5px;
      width: 100%;
      text-align: right;

    }
    &-block-div {
      width: 100%;
      min-height: 365px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      padding: 0 25px;
      flex-direction: column;
    }
    &-block-img {
      width: 204px;
      height: 204px;
    
    }
    .div1 {
      background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg9_m.png');
      background-size: 100% 100%;
    }
  
    .div2 {
      background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg10_m.png');
      background-size: 100% 100%;
    }
  
    .div3 {
      background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg11_m.png');
      background-size: 100% 100%;
    }
  }
  .banner-anim-thumb {
    bottom: 180px;
  }
}
