@content6: content6;

.conte5{
  overflow: hidden !important;
}
.@{content6}-wrapper {
  min-height: 100vh;
  background-color: #c55a48;

  overflow: inherit !important;

  // overflow: hidden !important;
  .bg {
    width: 100%;
    height: 100vh;
    top: 0;
    background-image: url('../../../public/home/bg1.png');
    background-size: cover;
    // background-attachment: fixed;
    background-position: center;
    z-index: 10;
  }
  .point {
    background-image: url('../../../public/home/point.png');
    background-size: 100% 100%;
    width: calc(7.5rem / @rate);
    height: calc(7.5rem / @rate);
  }
  .point-block {
    position: absolute;
    left: calc(7.5rem / @rate * -1);
    top: -20px;
  }
  .point-line {
    height: 10px;
    background-color: #fff;
    width: 100px;
    position: absolute;
    right: calc(7.5rem / @rate / 2);
    top: 50%;
    margin-top: -5px;
  }
  .list-block-mask {
    position: sticky;
    width: 100%;
    height: 300px;
    background: linear-gradient(to bottom, #c55a48 30%, rgba(145, 152, 229, 0));
    margin-top: -100px;
    z-index: 9;
    top: 0;
  }
  .list-block {
    // height:400px;
    height: 100%;
    max-width: calc(30rem / @rate);
    position: relative;
    .list-title {
      color: #fff;
      font-family: Optima;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #fff;
      text-align: center;
      line-height: calc(6.25rem / @rate);
    }

    .list-desc {
      color: #fff;
      text-align: center;
      font-family: Optima;
      font-style: normal;
      font-weight: 400;
      margin-bottom: calc(5.44rem / @rate);
      height: calc(6.25rem / @rate);
      line-height: normal;
    }

    .small {
      opacity: 0.7;
      .list-title {
        font-size: calc(5rem / @rate);
      }
      .list-desc {
        font-size: calc(1.5rem / @rate);
      }
    }

    .mid {
      opacity: 1;
      .list-title {
        font-size: calc(7.25rem / @rate);
      }
      .list-desc {
        font-size: calc(2rem / @rate);
      }
    }

    .large {
      opacity: 1;
      .list-title {
        font-family: Optima;
        font-size: calc(8rem / @rate);
        font-weight: 400;
      }
      .list-desc {
        font-size: calc(1.5rem / @rate);
        font-weight: 400;
      }
    }
  }
  .content6-child-text {
    color: #fff;
    text-align: justify;
    font-family: Optima;
    font-size: calc(3.75rem / @rate);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: calc(16.62rem / @rate);

    // letter-spacing: calc(0.075rem / @rate);
  }
  .@{content6} {
    height: 100%;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    &-title,
    &-content {
      color: #fff;
      text-align: justify;
      font-family: Optima;
      font-size: calc(3rem / @rate);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &-text {
      min-height: 424px;
      position: sticky !important;
      top: 0;
      > *.queue-anim-leaving {
        position: relative !important;
      }
      .title-h1 {
        position: relative;
        margin: 0 0 16px;
        text-align: left;
        font-size: 2em;
      }
      .title-content {
        position: relative;
        margin-bottom: 64px;
        text-align: left;
      }
      ul {
        position: relative;
        display: inline-block;
        li {
          margin-bottom: 24px;
          .@{content6}-icon {
            display: inline-block;
            width: 30px;
            height: 30px;
            position: absolute;
          }
          .@{content6}-title,
          .@{content6}-content {
            margin-left: 45px;
          }
          .@{content6}-title {
            font-size: 14px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content6}-wrapper {
    height: 860px;
    overflow: hidden;
    .content6-text{
      position: relative !important;
    }
    .@{content6} {
      display: block;
      &-img,
      &-text {
        display: block;
        width: 100%;
      }
      &-text {
        > h1,
        > p {
          text-align: center;
        }
        > h1 {
          margin: 56px auto 16px;
        }
        p {
          margin-bottom: 32px;
        }
      }
      &-img {
        margin-top: 20px;
      }
    }

    .content6-text{
      min-height: auto;
    }
    .content6-child-text{
      margin-top:24px;
      font-size: 24px;
    }
    .list-block-mask{
      display: none;
    }
    .list-title{
      font-size: calc(4rem / 1.406) !important;
    }

    .list-desc{
      margin-bottom:0 !important
    }
   
  }
 
}
