.explore{
    &-content{
        margin-bottom:50px
    }
 &-title{
    color: var(--Style, #826AC8);
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px; /* 140% */
    margin-bottom: 20px;
 }
 &-tip{
    color: #434343;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 136.364% */
    margin-bottom: 16px;
    padding: 20px 0;

 }
 &-tip1{
    color: var(--c-45-a-48, #C45A48);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px; /* 116.667% */
    margin-bottom: 30px;
 }
 &-chat{
   
    &_tip{
        color: #54585A;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin-bottom: 64px;
        span{
            color: #C45A48;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
            cursor: pointer;
        }
    }
 }

 &-table {

    margin-bottom: 50px;

    &_head{
        margin-bottom: 18px;
        display: flex;
        justify-content: space-between;
    }
    &_title{
        color: var(--c-45-a-48, #C45A48);
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: 28px; /* 100% */
    }

    &_opt {
        display: flex;
        justify-content: space-between;
        &_itemline-active{
            background: #826AC8;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            width: 44px;
            height: 2px;
            display: inline-block;
            position:absolute;
            bottom: -5px;
            left: 50%;
            margin-left: -22px;

        }
        &_item{
            flex: 1;
            position: relative;
            cursor: pointer;
            &_active{
                color: var(--Style, #826AC8) !important;
                font-weight: 600 !important;
            }
        }
        &_itemtext{
            white-space: nowrap;
            margin: 0 25px;
        }
    }
 }
}

@media screen and (max-width: 1200px) {
    .explore-title{
        // font-size: 24px;
    }
    .explore-tip{
        font-size: 18px;
        padding:  0;
    }
   
}
@primary-color: #A01E28;