.resources {
    &-content {
        margin-bottom: 50px
    }

    &-head{
        position: relative;
        // max-width: 1200px;
        margin-bottom: 21px;

    }
    &-bg{
        height: 100%;
        background: rgba(237, 143, 75, 0.20);
        border: 1px solid #E5E5E5;
        position: absolute;
        width: 200vw;
        top: 0;
        left: 0;
        transform: translateX(-50%);
    }
    &-top {
        position: relative;
        z-index: 9;
        padding: 21px 22px;
        background-color: #FBE9DB;

        &-content {
            // max-width: 1200px;
            margin: auto;
        }
    }

    &-title {
        color: #000;
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        /* 114.286% */
    }

    &-tip {
        color: #000;
        font-family: Inter;
        font-size: 12.797px;
        font-style: normal;
        font-weight: 300;
        line-height: 26px;
        /* 203.175% */
        margin-bottom: 8px;
    }

    &-tip1 {
        color: #96B05A;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        cursor: pointer;
    }

    &-body{
        margin-bottom: 50px;
        &-tip{
            color: #000;
            font-family: Inter;
            font-size: 12.797px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px; /* 203.175% */
            margin-bottom: 14px;
        }

        &-content{
            color: #000;
            font-family: Inter;
            font-size: 12.797px;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 14px;
            line-height: 26px; /* 203.175% */
        }

        &-desc{
            color: #54585A;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 171.429% */
        }
        &-link{
            color: #7E3A2E;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-decoration-line: underline;
            cursor: pointer;
        }
    }
}
@primary-color: #A01E28;