@import '~antd/lib/style/themes/default.less';

@line-color: #e9e9e9;

@shadow-color: rgba(0, 0, 0, 0.15);

@bottom-bar-bg-color: #262626;
@bottom-bar-line-color: #000;

@template-bg-color: #001529;
@template-bg-color-light: #ececec;
@template-nav-bg-color: #001529;
@template-text-color: #ccc;
@template-text-title-color: #bcbcbc;
@template-text-color-light: #fff;
@template-footer-text-color: #999;

@animate-duration: 0.45s;
@rate: 1.406;

/* 详细页图片或框框的样式;
*/
p {
  margin-bottom: 0;
}

.page-shadow() {
  box-shadow: 0 5px 8px @shadow-color;
}

.page-pro() {
  border-radius: 6px;
  border: 1px solid @line-color;
  transform: translateY(0);
  transition: transform 0.3s @ease-out, box-shadow 0.3s @ease-out;

  &:hover {
    .page-shadow();
    transform: translateY(-5px);
  }
}

.detail-content {
  padding: 0;
}

.detail-mask {
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: #000000;
  opacity: 0.5;
  position: absolute;
}

.detail-mask-img {
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  object-fit: cover;
}

.detail-title {
  position: relative;
  width: 100vw;
  min-height: calc(100vw / 6.508);
  padding: 0 54px;
  font-size: 88px;
  color: #fff;
  background-size: cover;
  background-position: center;
  font-family: Optima;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;

  // align-items: center;
}

.detail-title-text {
  max-width: 1340px;
  width: 1340px;
  padding: 0 24px 0 25px;
  font-family: Inter-Bold;
  font-weight: bold;
  font-size: 49px;
  color: #FFFFFF;
  text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin: auto;
}

// .detail-title {
//   color: #c55a48;
//   font-family: Optima;
//   font-size: calc(2.5rem / @rate);
//   font-style: normal;
//   font-weight: 700;
//   line-height: normal;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   flex-shrink: 0;
//   margin: 0 32px;
// }

.detail-time {
  display: flex;
  align-items: center;
  width: 1340px;
  padding: 0 24px 0 25px;
  margin: auto;

  &-div {
    width: 24px;
    height: 24px;
    background-color: #c55a48;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-bottom: 7px;
    }
  }

  &-text {
    color: #c55a48;
    font-family: Optima;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: calc(6.25rem / @rate);
    height: calc(6.25rem / @rate);
    // margin-left: 10px;
  }
}

.detail-img {
  width: 672px;
  height: calc(672px / 2.025);

  overflow: hidden;
  margin: auto;
  margin-bottom: 12px;

  // margin: 0 auto calc(6.19 rem / @rate) calc(4.87rem / @rate);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: calc(1.25rem / @rate);
  }
}

.detail-body-text {
  width: 672px;
  margin: auto;
  font-family: Inter-Regular;
  text-align: justify !important;

  ol {
    li {
      // margin-left: 1rem;
    }
  }

  img {
    max-width: 100% !important;
  }
}

.detail-menu {
  position: sticky;
  top: 120px;
  height: 500px;
  margin-right: 25px;
  width: 233px;
  border-top: 1px solid #EBEBEB;
  // flex: 1;
  // padding-left: 32px;
}

.detail-point-title {
  height: 15px;
  font-family: Inter-Regular;
  font-weight: 400;
  font-size: 11px;
  color: #B8B8B8;
  line-height: 15px;
  text-align: left;
  font-style: normal;
  margin: 26px 0 19px;
  text-transform: none;
}

.detail-point-list {
  flex: 1;
  margin-bottom: 40px;
}

.detail-row {
  display: flex;
  justify-content: center;
  padding: 0 32px;
  max-width: 1340px;
  margin: auto;
}

.detail-point {
  position: relative;
}

.detail-point-icon {
  width: calc(7.5rem / @rate);
  height: calc(7.5rem / @rate);
  background-image: url('../../../public/home/circle.png/');
  background-size: 100% 100%;
}

.detail-point-line {
  height: 500px;
  width: 3px;
  background-color: #c55a48;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1.5px;
}

.detail-point-item {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-bottom: 13px;

  font-family: Inter-Regular;
  font-weight: 400;
  font-size: 13px;
  color: #292929;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.ql-snow .ql-editor h1 {
  // font-family: Inter-Bold !important; 
  font-weight: bold;
}

.ql-snow .ql-editor h2 {
  // font-family: Inter-Bold !important; 
  font-weight: bold;
}

.ql-snow .ql-editor h3 {
  // font-family: Inter-Bold !important; 
  font-weight: bold;
}

.ql-snow .ql-editor h4 {
  // font-family: Inter-Bold !important; 
  font-weight: bold;
}

.ql-snow .ql-editor h5 {
  // font-family: Inter-Bold !important; 
  font-weight: bold;
}

.active {
  // margin-left: 10px  !important;
  color: #c55a48 !important;
}

.detail-point-block-title {
  font-family: Inter-Bold;
  font-weight: bold;
  font-size: 28px;
  color: #676599;
  line-height: 32px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-bottom: 19px;
}

.detail-point-block-div {
  width: 335px;
  height: 317px;
  flex-shrink: 0;
  border-radius: calc(0.625rem / @rate);
  padding: 24px 14px 22px;
  position: sticky;
  top: 120px;
  margin-left: 25px;
  border: 1px solid #EBEBEB;
}

.detail-point-block-input {
  width: 100%;
  height: 46px;
  border-radius: 6px;
  background: #F9F9F9;
  border: 1px solid #FFFFFF;
  padding: 0 10px;

  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    font-family: Inter-Regular;
    font-weight: 300;
    font-size: 15px;
    color: #5F636A;
    line-height: 19px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-family: Inter-Regular;
    font-weight: 300;
    font-size: 15px;
    color: #5F636A;
    line-height: 19px;
    text-align: left;
    font-style: normal;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-family: Inter-Regular;
    font-weight: 300;
    font-size: 15px;
    color: #5F636A;
    line-height: 19px;
    text-align: left;
    font-style: normal;
  }

  ::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: Inter-Regular;
    font-weight: 300;
    font-size: 15px;
    color: #5F636A;
    line-height: 19px;
    text-align: left;
    font-style: normal;
  }
}

.detail-point-block-sumbit{
  width: 100%;
  height: 46px;
 background: #DD9689;
 display: flex;
 align-items: center;
 justify-content:center;
 color: #fff;
 border-radius: 5px;
 margin-top:46px;
 margin-bottom: 15px;
 img{
  width: 27px;
  height: 27px;
  margin-left: 12px;
  object-fit: cover;
  border-radius: 50%;
 }
}

.ql-editor {
  padding: 0 !important;
}
.detail-top{
  cursor: pointer;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Inter-Regular;
  font-weight: 400;
  font-size: 16px;
  color: #292929;
  line-height: 29px;
  text-align: center;
  font-style: normal;
  text-transform: none;
  position: fixed;
  right: 30px;

  bottom: 50px;

  img{
    width:26px ;
    height: 26px;
    object-fit: cover;
  }
}
.back-btn {


  font-family: Inter-Regular;
  font-weight: 400;
  font-size: 13px;
  color: #C45B48;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.detail-point-block-btn:hover {
  background:  rgba(103, 101, 153, 1);
  color: rgba(254, 198, 54, 1);

}

.detail-point-block-btn {
  width: 100%;  
  margin-top: 10px;
  height: 46px;
  background: #C45B48;
  margin-bottom: 15px;
  cursor: pointer;
  color: #fff;
  font-family: Inter-Bold;
  font-weight: bold;
  font-size: 15px;
  color: #FFFFFF;
  line-height: 46px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

.detail-tip {
  font-size: 14px;
  color: #989aa5;
  font-weight: 300;
  // font-style: italic;
  font-family: Optima;

  margin-bottom: 20px;
}

.detail-point-block-tip {
  font-family: Inter-Regular;
  font-weight: 300;
  font-size: 11px;
  color: #989AA5;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.detail-point-block-tip1 {
  color: #676599 !important;
  font-family: Inter-Bold;
  font-weight: bold;
}

.detail-time-text {
  font-size: 32px;
  line-height: 85px;
  height: 85px;
  color: #b9b3b3;
  font-weight: 700;
  font-family: Inter-Regular;
  font-weight: 400;
  font-size: 11px;
  color: #A1A1A1;
  text-align: left;
  font-style: normal;
  text-transform: none;

}

.ql-align-center {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .detail-top{
    left: auto !important;
    right: 20px !important;
    width: 60px;
    height: 60px;
    font-size: 12px;
    line-height: 18px;
    img{
      width:16px ;
      height: 16px;
      object-fit: cover;
    }
  }
  .detail-body-text {
    width: 100vw;
    padding: 0 16px;
    
  }

  .detail-title-text {
    width: 100%;
    font-size: 18px;
    padding: 0;
  }

  .detail-time {
    padding: 0;
  }

  .detail-time-text {
    font-size: 14px;
  }

  .detail-content {
    padding: 0 24px 0;
    overflow-x: hidden;
  }

  .detail-title {
    font-size: 18px;
    margin: 0;
    margin-left: -24px;
    padding: 0 24px;
  }

  // .detail-time {
  //   margin: 10px 0;
  // }
  .detail-time-div {
    width: 24px;
    height: 24px;
  }

  .detail-time-text {
    font-size: 16px;
    line-height: 32px;
    height: 32px;
    color: #b9b3b3;
  }

  .detail-img {
    margin: 0;
    width: calc(100vw - 48px);
    height: calc((100vw - 48px) / 2.025);
    margin-bottom: 10px;
  }

  .detail-row {
    padding: 0 !important;
  }
}