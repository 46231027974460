@import '~antd/lib/style/themes/default.less';
@rate: 1.406;
@line-color: #e9e9e9;

@shadow-color: rgba(0, 0, 0, 0.15);

@bottom-bar-bg-color: #262626;
@bottom-bar-line-color: #000;

@template-bg-color: #001529;
@template-bg-color-light: #ececec;
@template-nav-bg-color: #001529;
@template-text-color: #ccc;
@template-text-title-color: #bcbcbc;
@template-text-color-light: #fff;
@template-footer-text-color: #999;

@animate-duration: 0.45s;

/* 详细页图片或框框的样式;
*/
.page-shadow() {
  box-shadow: 0 5px 8px @shadow-color;
}

.page-pro() {
  border-radius: 6px;
  border: 1px solid @line-color;
  transform: translateY(0);
  transition: transform 0.3s @ease-out, box-shadow 0.3s @ease-out;
  &:hover {
    .page-shadow();
    transform: translateY(-5px);
  }
}
.content {
  height: calc(100vh - 82px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.c-title {
  color: #c55a48;
  text-align: center;
  font-family: Optima;
  font-size: calc(7.5rem / @rate);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.c-email {
  color: #c55a48;
  text-align: center;
  font-family: Optima;
  font-size: calc(3.75rem / @rate);
  font-style: normal;
  font-weight: 900;
  line-height: calc(3.75rem / @rate);
}
.c-icon {
  display: flex;
  justify-content: space-around;
}
.c-icon-item {
  width: calc(4.625rem / @rate);
  height: calc(4.625rem / @rate);
  flex-shrink: 0;
  border-radius: calc(0.4375rem / @rate);
  background: #c55a48;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(2.63rem / @rate);
  margin-right: calc(1.12rem / @rate);
}

.x {
  background-image: url('../../../public/home/x.png');
  background-size: 100% 100%;
  width: calc(2.75rem / @rate);
  height: calc(2.4375rem / @rate);
}

.in {
  background-image: url('../../../public/home/in.png');
  background-size: 100% 100%;
  width: calc(4.625rem / @rate);
  height: calc(4.625rem / @rate);
}
.c-desc {
  color: #c55a48;
  text-align: center;
  font-family: Optima;
  font-size: calc(2rem / @rate);
  font-style: normal;
  font-weight: 400;
  height: calc(5.875rem / @rate);
  line-height: calc(5.875rem / @rate); /* 175% */
}
.c-tip{
  color: #000;
font-family: Optima;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: var(--Display-Small-Line-Height, 44px); /* 183.333% */
margin: 49px 0;
max-width:  840px;
padding: 0 10px;
}
@media screen and (max-width: 767px) {

.c-title{
 font-size: 32px;
}
.c-email{
  font-size:26px;
  margin-bottom: 30px;

}
.c-desc{
  font-size:18px
}
}