.chat {
    display: flex;
    width: 100%;
    max-width: 1437px;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 20px;
    background: var(---Fill-1-, #FFF);
    box-shadow: 0px 10px 97.5px 0px rgba(100, 100, 111, 0.15);
    min-height: 600px;
    margin-bottom: 20px;
    position: relative;

    &-block {
        // position: absolute;
        // top: 40px;
        display: flex;

        &-tag {
            display: flex;
            height: 40px;
            width: 56px;
            line-height: 40px;
            text-align: center;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
            border-radius: 8px;
            background: #F7F7F7;
            cursor: pointer;
            color: #344054;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            /* 142.857% */
            &_active {
                color: #826AC8 !important;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600 !important;
                line-height: 20px; 
            }
        }
    }
}


@media screen and (max-width: 1200px) {
    .chat{
        max-width: 90%;
        &-block {
            &-tag {
                height: auto;
                width: auto;
                padding: 5px 10px;
            }
        }
    }
    
  }
@primary-color: #A01E28;