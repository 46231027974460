.agix-content {
  position: relative;
  overflow: hidden;
}

.agix-bg {
  // background: #292929;

}

.agix-top {
  width: 100%;
  height: 526px;
  z-index: 100;
  position: fixed;
  top: 0;
}

.agix-top-menu {
  position: fixed;
  right: 12%;
  z-index: 100;
  top: 0;
  z-index: 9999;

}

.mobile-agix {

  .menu-btn {
    font-size: 10px !important;
    margin-left: 8px !important;
    width: auto !important;
    font-weight: 500;

  }

  .menuactive > .line1{
    width: 26px;
    margin-left: -13px;
    height: 2px;
  }

  .agix-top-content {
    padding: 20px 30px !important;
  }

  .agix-top-menu {
    right: 12px;
  }


  .agix-top {
    width: 100%;
    height: calc((100vw / 3.5));
  }

  .agix-content_title {
    font-size: 16px !important;
    line-height: 23px;
    font-weight: 900;
  }

  .agix-content_tip {
    font-size: 6px !important;
    line-height: normal;
    margin: 5px 0;
  }

  .agix-content_tip1 {
    font-size: 8px !important;
    line-height: normal;
    font-weight: 600;
  }

  .agix-body {
    padding-top: calc((100vw / 3.5));
    padding-left: 12px;
    padding-right: 12px;
    flex-direction: column;
  }

  .agix-body_left {
    top: 0;
    padding-top: 0;
    flex-basis: 65px
  }

  .agix-body_left_content {
    flex-direction: row;
    top: calc(((100vw / 3.5))) !important;
    width: calc(100%);
    justify-content: space-between;
    background-color: #fff;
    margin-left: -12px;
    padding: 10px 12px;

  }

  .agix-opt-menu {
    width: 78px !important;
    height: 30px;
    padding: 0;
    font-size: 9px;
    margin: 0;
    border-radius: 6px;
  }


  .explore-title {
    font-size: 15px;
    margin: 0;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .explore-tip {
    font-size: 11px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  .explore-tip1 {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 5px;
  }

  .chat {
    max-width: 100% !important;
    padding: 12px 12px 12px 12px;
  }

  .chat-block {
    margin-bottom: 0;
    width: 100%;
    justify-content: space-between;
    padding: 0 12px;
  }

  .chat-block-tag {
    width: 32px;
    height: 22px;
    font-size: 12px !important;
    border-radius: 4px;
    margin-right: 0;
  }

  .chat {
    min-height: 339px;
    margin-bottom: 8px;
    border-radius: 4px;
  }

  .explore-chat_tip {
    margin-bottom: 16px;
    font-size: 8px;
  }

  .explore-table_title {
    font-size: 16px;
  }

  .explore-table_opt_itemtext {
    font-size: 10px;
    margin: 0 0 0 12px;
  }

  .explore-table_opt_itemline-active {
    bottom: 0;
    width: 24px;
    margin-left: -8px;
  }

  .explore-table_head {
    margin-bottom: 10px;
  }

  .table_head {
    border-radius: 0;
  }

  .table_head_cols {
    font-size: 8px !important;
    line-height: 40px;
    height: 40px;
  }

  .table_cols {
    padding: 5px 0;
    height: auto;
    min-height: 27px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    line-height: normal;
    font-size: 10px;
  }

  .explore-content {
    margin-bottom: 12px
  }

  .philosophy-title {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }

  .philosophy-block {
    flex-direction: column;
  }

  .philosophy-block_item {
    width: 100%;
    height: 110px;
    margin-bottom: 10px;
    border-radius: 6px !important;
    border: 1px solid var(--03, #5E3290);
    background: #FFF;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  }

  .philosophy-block_item:hover {
    width: 100% !important;
  }

  .philosophy-block .a:hover .philosophy-block_icon {
    transform: translateY(-23%) translateX(calc(-146px));
  }

  .philosophy-block .b:hover .philosophy-block_icon {
    transform: translateY(-23%) translateX(calc(-146px));
  }

  .philosophy-block .c:hover .philosophy-block_icon {
    transform: translateY(-23%) translateX(calc(-146px));
  }

  .philosophy-block_item:hover .philosophy-block_logos img {
    width: 170px
  }

  .philosophy-block_item:hover .philosophy-block_logos {
    transform: translateY(25px) translateX(70px)
  }


  .philosophy-block_icon {
    width: 30px;
    height: 30px;
    margin-bottom: 3.5px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  .philosophy-block_name {
    margin-bottom: 5px;
    font-size: 13px;
    line-height: normal;
  }

  .philosophy-block_desc {
    font-size: 9px;
    line-height: normal;
  }

  .philosophy-block {
    margin-bottom: 22px;
  }

  .philosophy-tip {
    padding: 10px;
    border-radius: 0;
    margin-bottom: 7px;
  }

  .philosophy-tip1 {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 5px;
  }

  .philosophy-tip2 {
    font-size: 10px !important;
    line-height: 20px;
  }

  .philosophy-quadrant_line2_text {
    font-size: 10px !important;
    line-height: normal;
  }

  .philosophy-quadrant_itembgdesc {
    padding: 28px 10px;
    font-size: 7px;
    line-height: 14px
  }

  .philosophy-end_row {
    flex-direction: column;
    margin-bottom: 10px;
  }

  .philosophy-end_row_title {
    font-size: 10px;
    line-height: 12px;
    flex-basis: 0;
  }

  .philosophy-end_row_desc {
    font-size: 8px;
    line-height: 20px;
  }

  .philosophy-quadrant_name {
    width: 58px;
    height: 24px;
    font-family: "Fira Code";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 120% */
  }

  .philosophy-quadrant_item {
    height: 110px;
    margin-left: 10px;
  }

  .philosophy-quadrant_line1_text {
    font-size: 10px !important;
    font-weight: 500;
    line-height: normal;
  }

  .philosophy-quadrant_row {
    margin-bottom: 10px;
  }

  .philosophy-quadrant {
    width: calc(100% - 10px);
    margin-left: 10px;
    margin-bottom: 50px;

    .philosophy-quadrant_line1_text {
      left: -34px;
    }

    .left-top {
      left: 5px;
      top: 5px;
    }

    .right-top {
      right: 5px;
      top: 5px;
    }

    .left-bottom {
      left: 5px;
      bottom: 5px;
    }

    .right-bottom {
      right: 5px;
      bottom: 5px;
    }
  }

  .philosophy-content {
    margin-bottom: 17px
  }

  .philosophy-end {
    margin-bottom: 17px
  }

  .companies-title {
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 6px;
  }

  .companies-tip_text1 {
    font-size: 8px;
    margin-bottom: 10px;
  }

  .companies-tip_text2 {
    font-size: 7px;
    line-height: normal;
    margin-bottom: 18px;
  }

  .companies-tip_opt {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .companies-tip_opt {
    font-size: 10px;
    line-height: normal;
  }

  .companies-deep_title {
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 11px;
  }

  .companies-table {
    margin-bottom: 18px
  }

  .companies-deep_block {
    flex-direction: column;
    padding: 13px;
  }

  .companies-deep_block_img {
    width: 100%;
    max-height: 152px;
    margin-right: 0;
    margin-bottom: 20px
  }

  .companies-deep_block_tag {
    border-radius: 4px;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    width: 106px;
    height: 40px;
    display: inline-block;
    padding: 0 !important;
    line-height: 40px !important;
  }

  .companies-deep_block_title {
    margin-top: 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .companies-deep_btn {
    padding: 0;
    width: 132px;
    height: 24px;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 350% */
    border-radius: 4px;
    margin-right: 0;

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .companies-deep {
    margin-bottom: 54px;
  }

  .resources-top {
    padding: 12px 17px;
    background-color: #FBE9DB
  }

  .resources-bg {
    display: none;
  }

  .resources-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 4px;
  }

  .resources-tip {
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .resources-tip1 {
    font-size: 11px
  }

  .resources-head {
    margin-bottom: 18px
  }

  .resources-body-tip {
    font-size: 16px;
    margin-bottom: 3px
  }

  .resources-body-content {
    text-align: justify;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    /* 140% */
    margin-bottom: 0px;
  }

  .resources-body-desc {
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    /* 175% */
  }

  .resources-body-link {
    font-size: 8px;
  }

  .agixfooter-content {
    flex-direction: column;
  }

  .agixfooter-left {
    padding: 23px 0;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .agixfooter-left-title {
    padding: 0;
    margin-bottom: 6px;
  }

  .agixfooter-left-desc {
    color: var(--d-9-d-9-d-9, #333);
    font-family: "Abhaya Libre ExtraBold";
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.32px;
  }

  .agixfooter-right {
    padding: 24px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .agixfooter-right_tip {
    font-size: 18px;
    margin-bottom: 9px;
  }

  .modal-content {
    width: 331px;
    height: 184.26px;
    border-radius: 6px;
    margin-left: -165px;
    padding: 17px 22px;
  }

  .modal-content-text {
    font-size: 12px
  }

  .modal-content-footer {
    margin-top: 14px;
    justify-content: center;
  }

  .modal-content-footer_cancel,
  .modal-content-footer_proceed {
    width: 100px;
    height: 37px;
    border-radius: 4px;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
}

.agix-top-content {
  max-width: 1200px;
  margin: auto;
  position: relative;
  // top: 50%;
  // margin-top: -163.5px;
  // transition: top 0.3s ease, margin-top 0.3s ease;
  height: 100%;
  display: flex;
  align-items: center;

}

.agix-content_title {
  color: var(--ffffff, #FFF);
  /* Drop Shadow/400 */
  text-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-color-black-200), var(--sds-size-depth-0) var(--sds-size-depth-100) var(--sds-size-depth-100) var(--sds-color-black-100);
  font-family: Inter;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  transition: font-size 0.3s ease;
}

.agix-content_tip {
  color: var(--ffffff, #FFF);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  /* 222.222% */
  opacity: 0.8;
  margin: 18px 0;
  transition: font-size 0.3s ease;
}

.agix-content_tip1 {
  color: var(--ffffff, #FFF);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  /* 142.857% */
  transition: font-size 0.3s ease;
}


.agix-body {
  padding: 0 32px;
  max-width: 1200px;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
}

.agix-top-content_left {
  min-width: 186px;
  flex-basis: 186px;
  flex-shrink: 0;
  height: 10px;
}

.agix-body_left {
  position: relative;
  top: 526px;
  z-index: 1000;
  padding-top: 526px;
  min-width: 186px;
  flex-basis: 186px;
  flex-shrink: 0;
  // flex-shrink: 0;
  // position: fixed;
  // left: 20px;
  // padding-right: 60px;
  display: flex;
  z-index: 99;
  // margin-right: 40px;
  // justify-content: end;
}

.agix-body_left_content {
  display: flex;
  position: fixed;
  // top: 0;
  left: 0;
  flex-direction: column;
  // transform: translateX(50%);
  // margin-left: -35px;
}

.agix-body_center {
  flex: 1;
  max-width: calc(100% - 186px);
  padding-right: 20px;
}

.agix-opt-active {
  background: #C45A48 !important;
  color: #fff !important;

}

.agix-opt-menu {
  width: 156px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  text-align: center;
  padding: 10px 30px;
  border-radius: 14px;
  background: #F6F6F6;
  color: #000;
  margin-bottom: 20px;
  cursor: pointer;

  span {
    opacity: 0.8;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }
}

.agix-body {
  padding: 50px 0 0;
}

.filter-content_bg1 {
  position: absolute;
  left: 110%;
  bottom: 0;
}

.filter-content_bg2 {
  position: absolute;
  left: 100%;
  bottom: -200px;
}


.table_head {
  display: flex;
  background: var(--d-9-d-9-d-9, #333);
  border-radius: 16px 16px 0 0;
}

.table_head_cols {
  flex: 1;
  text-align: center;
  color: var(--ffffff, #FFF);
  text-align: center;
  font-family: "Fira Code";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 80px;
  line-height: 80px;
}

.table_rows {
  background: #fff;
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.20);
  transition: background-color 0.3s ease;
  position: relative;
  z-index: 9;
}

.table_rows::last-child {
  border-bottom: none !important;
}

.table_rows:hover {
  border-bottom: 1px solid rgba(0, 0, 0, 0.20) !important;
  background: rgba(51, 51, 51, 0.10) !important;
}

.table_cols {
  height: 54px;
  line-height: 54px;
  color: var(--d-9-d-9-d-9, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  flex: 1;
}

.modal-content {
  max-width: 100%;
  width: 650px;
  height: 236px;
  // background-image: url(../../public/agix/modalbg.png);
  background-color: #3d4055;
  background-size: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -325px;
  z-index: -1;
  // z-index: 999;
  padding: 40px;
  opacity: 0;
  transition: opacity 0.3s ease, z-index 0.3s ease;
}

.modal-content_active {
  z-index: 999;
  opacity: 1;
}

.modal-content-text {
  color: var(--ffffff, #FFF);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  /* 141.667% */
}

.modal-content-footer {
  display: flex;
  margin-top: 30px;
}

.modal-content-footer_cancel {
  display: flex;
  width: 155px;
  height: 37px;
  line-height: 37px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #826AC8;
  color: var(--ffffff, #FFF);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  cursor: pointer;
  margin-right: 20px;
}

.modal-content-footer_proceed {
  display: flex;
  width: 155px;
  height: 35px;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #FFF;
  color: var(--Style, #826AC8);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  cursor: pointer;
  line-height: normal;
}

@media screen and (max-width: 1300px) {
  .agix-top-content {
    max-width: 100%;

  }

  .agix-body {
    max-width: 100%;
  }
}

@media screen and (max-width: 1500px) {

  // .agix-body_left_content {
  //   flex-direction: row;
  // }
  // .agix-body_left{
  //   left: 50%;
  // }
  .agix-opt-menu {
    margin: 0 10px 10px;
    padding: 10px;
    width: 120px;
  }
}

@media screen and (max-width: 1200px) {
  .agix-content_title {
    font-size: 60px !important;
  }

  .agix-content_tip {
    margin: 0
  }

  .agix-top-content {
    padding: 40px !important
  }

  .agix-content_tip1 {
    font-size: 20px !important;
  }

  .agix-body_left {
    // display: none;
  }

  .agix-body {
    max-width: 100%;
  }

  .agix-body_center {
    max-width: calc(100% - 186px);
    padding: 0 24px;

  }
}
@primary-color: #A01E28;