@teams3: teams3;

.@{teams3}-wrapper {
  min-height: 446px;
  overflow: hidden;

  .@{teams3} {
    overflow: hidden;
    height: 100%;
    padding: 64px 24px;

    > .title-wrapper {
      margin: 0 auto 48px;
    }

    .block-top-wrapper {
      position: relative;
      height: 100%;
      overflow: hidden;
      padding: 20px 0;
      .block-top {
        display: inline-block;
        text-align: center;
        margin-bottom: 70px;
        &.queue-anim-leaving {
          position: relative !important;
        }
      }
    }
    &-top-image,
    &-top-title,
    &-top-job,
    &-top-content {
      margin: auto;
      line-height: 1.5;
      text-align: left;
    }

    &-top-image {
      color: #404040;
      object-fit: cover;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
      }
    }
    &-top-title {
      font-size: 24px;
      font-family: 'DM Serif Display';
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 120%;
      color: #000000;
      text-transform: capitalize;
      text-align: left;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      a {
        color: #000 !important;
      }
    }

    &-top-job {
      font-family: 'HK Grotesk';
      font-style: normal;
      font-weight: 400;
      font-size: 15px !important;
      line-height: 160%;
      text-transform: capitalize;
      color: #000000 !important;
      text-align: left;
      white-space: normal;
      margin: 24px 0 42px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    &-top-job,
    &-top-content {
      font-size: 12px;
      color: #919191;
    }

    .block-wrapper {
      position: relative;
      height: 100%;
      overflow: hidden;
      padding: 20px 0;
      min-height: 456px;
      margin-top: 48px;
      .block {
        margin-bottom: 48px;
        vertical-align: text-top;

        &.queue-anim-leaving {
          position: relative !important;
        }
      }
    }

    &-image {
      color: #404040;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-textWrapper {
      padding-left: 16px;
    }

    &-title {
      font-size: 18px;
      margin-bottom: 2px;
      a {
        color: #000 !important;
      }
    }

    &-job {
      margin-bottom: 4px;
    }
    &-job-div {
      text-align: left;
    }
    &-job,
    &-content {
      font-family: 'HK Grotesk';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 160%;
      text-transform: capitalize;
      color: #000000;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .teams3-job-span {
      margin-bottom: 42px;
    }

    .teams3-job-tip {
      font-family: 'HK Grotesk';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 160%;
      display: flex;
      text-transform: capitalize;
      color: rgba(0, 0, 0, 0.44);
      margin-bottom: 6px;
    }
    .teams3-top-content-avatar{
      width: 48px;
      height: 48px;
    }
  }
}

@media screen and (max-width: 767px) {
  .@{teams3}-wrapper {
    min-height: 1440px;

    .@{teams3}.home-page {
      padding-bottom: 0;
      padding: 24px;
    }
    .@{teams3}{
      
      &-job{
        font-size: 12px ;
       line-height: 22px;
      //  margin-bottom: 18px!important;
      }
      &-textWrapper {
        padding-left: 0;
      }
      .teams3-job-tip{
        margin-top: 20px;
      }
      .teams3-top-content-avatar{
        width: 38px;
        height: 38px;
      }
      .teams3-top-content-text{
        font-size: 13px;
      }
      &-top-title {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 6px;
        a {
          color: #000 !important;
        }
      }
      .teams3-job-span{
        margin-bottom:18px;
      }
      &-top-job{
        font-size: 12px;
      line-height: 22px;
      margin-bottom:18px;
      margin-top:0;
      }
    }
  }
  
 
}
